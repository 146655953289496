import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Pipes from '../assets/Pipes.png';
import Wire from '../assets/wire.png';
import WireMob from '../assets/wire_mob.png';


const Services = () => {

    const { t, i18n } = useTranslation();
    const service = useRef(null);


    return (

        <div className="centerServicesContainer" id="Services">

            {/* <div className="wireMob">

                <img src={WireMob} alt="Wire" />
            </div> */}
            <div className="servicesLeft pipes1">
                {/* <div className="pipes1" > */}
                    {/* <div className="pipeContent"> */}
                        <a className="serviceName">
                            {t('paslaugos')}
                        </a>
                        <div className="servicesDotPos">

                            <div className=''>
                                <span className="servicesDot">
                                    <div className='txtCont'>
                                        <a className='servicesDotTxt'>
                                            {t('tik_kortele')}
                                        </a>
                                    </div>
                                </span>
                            </div>
                        </div>
                    {/* </div> */}
                    <img src={Pipes} alt="Pipes" />
                {/* </div> */}
            </div>

            <div className="servicesRight">
                <div className="contentContainer">
                    <div>
                        <h1>{t('remontas')}</h1>
                    </div>
                    <div className="servicesTxtCont">
                        <a className="servicesMainTxt">{t('remontas_txt')}</a>
                    </div>
                    <div className="ieskote">
                        <a className="servicesMainTxt">
                            {t('remontas_ieskot')}
                        </a>
                        <a href='./Sugedo' className="btnKviesti">
                            {t('Išsikviesti')}
                        </a>
                    </div>
                    <div className="servicesPrice">
                        <div className="servicesPriceLeft">
                            <div className="eurCont">
                                <a className="eur">
                                    {t('60')}
                                </a>
                                <a className="eur2">
                                    {t('eur')}
                                </a>
                            </div>
                            <a className="fiksuotas">
                                {t('fiksuotas')}
                            </a>
                        </div>
                        <div className="servicesPriceRight">
                            <a className="priceRightTxt">
                                {t('neiskaiciuota')}
                            </a>
                            <a className="priceRightTxt">
                                {t('bendra_samata')}
                            </a>
                            <a className="priceRightTxt">
                                {t('atsisakyti')}
                            </a>
                            <p className="priceRightTxt" >
                                {t('privatumo_politika')} <a className="juodasTxt" href="./Politika" target={'_blank'}> {t('cia')}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services