import React, { useState, createContext, } from "react";

export const TaskContext = createContext();
const TaskContextProvider = (props) => {
    // const [tasks, setTasks] = useState('');
    const [path, setPath] = useState('https://ukvedziai-server.herokuapp.com/api/')
    // const [path, setPath] = useState('http://localhost:1337/api/')
    return (
        <TaskContext.Provider value={{ path }}>
            {props.children}
        </TaskContext.Provider>
    );
};
export default TaskContextProvider

