import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import home_ukv from '../assets/home_ukv.png';
import Home_pav from '../components/Home_pav.js'
import Services from './Services.js'
import ServicesIrengimas from '../routes/ServicesIrengimas.js'
import News from "./News.js";
import About from "./About.js";
import GarantijaServ from "./GarantijaServ.js";
import Naujienlaiskis from "./Naujienlaiskis.js";
import Footer from "./Footer";
import FooterMob from "./FooterMob";





const Home = () => {
    const { state } = useLocation()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [href, setHref] = useState('')
    useEffect(() => {
        if (window.location.pathname != "/") {
            setHref(window.location.pathname.replace("/", ''))
        }
    }, [])

    useEffect(() => {
        if (state?.scrollTo) {
            let e = document.getElementById(state.scrollTo)
            if (e)
                e.scrollIntoView({ behavior: "smooth", });
        }
    }, [state])
    const coursesPage = () => {
        navigate('/Avarine');

    }
    const coursesPage2 = () => {
        navigate('/Irengimas');
    }
    const coursesPage3 = () => {
        navigate('/Sugedo');
    }

    return (
        <div className="viskas" id='home' >
            <div className="centerContainer"  >
                <div className="kaire">

                    <Home_pav />
                </div>
                <div className="desine">

                    <div className="homeRight">
                        <div className="homeRightTop">
                            {t('uzklupo_problema')}
                        </div>
                        <div className="homeRightBottom">
                            <div className="column" onClick={coursesPage3}>
                                <div className="text">
                                    <h2> {t('sutaisyti')}</h2>
                                    <p>{t('sutaisyti_po')}</p>
                                </div>
                                <div className="btnCntr">
                                    <button onClick={coursesPage3} className="homeBtn">{t('testi')}</button>
                                </div>
                            </div>
                            <div className="column2" onClick={coursesPage2}>
                                <div className="text">
                                    <h2>{t('isirengti')}</h2>
                                    <p>{t('isirengti_po')}</p>
                                    <a></a>
                                </div>
                                <div className="btnCntr">
                                    <button onClick={coursesPage2} className="homeBtn">{t('testi')}</button>
                                </div>
                            </div>
                            <div className="column3" onClick={coursesPage}>
                                <div className="text">
                                    <h2>{t('avarine')}</h2>
                                    <p>{t('avarine_po')}</p>
                                    <a className="tel">{t('avarine_tel')}</a>
                                </div>
                                <div className="btnCntr">
                                    <button onClick={coursesPage} className="homeBtn">{t('testi')}</button>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Services />
            <ServicesIrengimas />
            <GarantijaServ />
            <News />
            <About />
            {/* <Naujienlaiskis /> */}
            <Footer />
            <FooterMob />
            {/* <div className='aboutDotPos2'>
                <a className='aboutDot2' onClick={() => { setHref('') }} href="/#home">
                    <p className='aboutDotTxt2'>
                        {t('↑')}
                    </p>
                </a>
            </div> */}
        </div>
    )
}

export default Home