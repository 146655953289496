import { useTranslation } from "react-i18next";
// import home_ukv from '../assets/home_ukv.png';
import Home_pav from '../components/Home_pav.js'
import { useNavigate } from 'react-router-dom';
// import Calendar from 'react-calendar';
import { useContext, useEffect, useState } from "react";
import { loadCldr } from '@syncfusion/ej2-base';
import { Calendar } from 'react-multi-date-picker';
import Modal from 'react-modal';
import * as lt from 'cldr-data/main/lt/ca-buddhist.json';
import * as numbers from 'cldr-data/main/lt/numbers.json';
import * as timeZoneNames from 'cldr-data/main/lt/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { L10n } from "@syncfusion/ej2-base";
import IskvietimasPng from '../assets/iskvietimas.png';
import AddImg, { ImagesAdd } from "../components/ImageAdd.js";
import { TaskContext } from "../AppStates";
import PhoneInput from 'react-phone-number-input'
import { useRef } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Checkbox } from '@mui/material';
import { random } from "@mui/x-data-grid-generator";
import Select from 'react-select'
// import Autocomplete from "react-google-autocomplete";
import AutoComplete from '../components/AutoComplete'
import Laikrodis from "../components/Laikrodis.js";




const Iskvietimas = ({ datos, kategorija }) => {
    ///////////////const ///////////////
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { path } = useContext(TaskContext);

    loadCldr(numberingSystems, lt, numbers, timeZoneNames);
    const [darbas, setDarbas] = useState({
        text: '',
        adresas: '',
        numeris: '',
        elPastas: '',

    })
    const [text, setText] = useState('')
    const [adresas, setAdresas] = useState('')
    const [numeris, setNumeris] = useState('')
    const [elPastas, setElPastas] = useState('')
    // const [numeris,setNumeris]=useState('')
    const [date, setDate] = useState(datos);
    useEffect(() => { setDate(datos) }, [datos])
    const [cdate, csetDate] = useState(datos);
    const [kdate, ksetDate] = useState([]);
    const [img, setImg] = useState(['', '', '', '', '', ''])
    const [laikas, setLaikas] = useState('')
    const [laikai, setLaikai] = useState([])
    const [vardas, setVardas] = useState('')
    const [pavarde, setPavarde] = useState('')
    const [checked, setChecked] = useState(false)
    const [iPav, setIPav] = useState('')
    const [iAdresas, setIAdresas] = useState('')
    const [iKodas, setIKodas] = useState('')
    const [iPvm, setIPvm] = useState('')
    const [asmKodas, setAsmKodas] = useState('')
    const kalendorius = useRef()
    const adresRef = useRef({})
    const [randomKodas, setRandomKodas] = useState('')
    const [randomVal, setRandomVal] = useState('')
    const [inputs, setInputs] = useState({});
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpen2, setModalOpen2] = useState(false)
    const [vardasFzn, setVardasFzn] = useState('')
    const [pavardeFzn, setPavardeFzn] = useState('')
    const [adressFzn, setAdressFzn] = useState('')
    const [butoNr, setButoNr] = useState('')
    const [namoNr, setNamoNr] = useState('')
    const [papildomaAdresui, setPapildomaAdresui] = useState('')
    const [saviv, setSaviv] = useState('')
    const [defaultValue, setDefaultValue] = useState("");
    const [vietove, setVietove] = useState('')


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    //////////////////////functions//////////

    L10n.load({
        'lt': {
            'calendar': { today: '' }
        }
    });
    useEffect(() => {
        setDarbas({
            Laikas: [laikas],
            adress: vietove + ' ' + adresas,
            data: [getData(kdate)],
            email: elPastas,
            problema: text,
            statusas: 'Nepriskirtas',
            tel: numeris,
            kategorija: kategorija[1],
            subKategorija: kategorija[2],
            imonesPavadinimas: iPav,
            imonesAdresas: iAdresas,
            imonesKodas: iKodas,
            PVM: iPvm,
            reikalingaS: checked,
            vardas: vardas,
            pavarde: pavarde,
            asmensKodas: asmKodas,
            uzsakovoTipas: sfOp.value,
            asmensAdresas: adressFzn,
            butoNr: butoNr,
            namoNr: namoNr,
            papildomaAdresui: papildomaAdresui,

        })
        console.log(darbas, adresas)
    }, [date, text, iPav, iAdresas, adresas, iKodas, iPvm, checked, vardas, numeris, elPastas, laikas, pavarde, pavardeFzn, vardasFzn, adressFzn, asmKodas, namoNr, butoNr, papildomaAdresui])


    const options = [
        { value: t('fizinis_asmuo'), label: t('fizinis_asmuo') },
        { value: t('juridinis_asmuo'), label: t('juridinis_asmuo') }
    ]

    const options2 = [
        { value: t('vilnius'), label: t('vilnius') },
        { value: t('vilnius_r'), label: t('vilnius_r') },
        { value: t('trakai_r'), label: t('trakai_r') }
    ]

    const handleSelect = (option) => {
        setSaviv(option);
        setVietove(option.value)
        // setAdresas(""); // clear adresas input when saviv changes
        // setAdresas(option.value.toString() + " " + adresas); // concatenate saviv with adresas
        console.log('cia', option.value, adresas)
    };

    useEffect(() => {
        const defaultVal = adresas ? adresas : '';
        setDefaultValue(defaultVal);
    }, [adresas]);

    const [sfOp, setSfOp] = useState('')

    const handleSubmit = (event) => {
        // event.preventDefault();
        var x = img.filter(element => {
            if (element !== '' || element !== undefined) return element
        });
        try {
            // if (!x[0]) {
            //     console.log(x)
            //     throw alert('Įkelkite bent 1 nuotrauką')
            // }
            if (!problemValidation(vardas)) {
                throw alert("Įrašykite vardą")
            }
            if (!problemValidation(pavarde)) {
                throw alert("Įrašykite pavardę")
            }
            if (!problemValidation(text)) {
                throw alert("Aprašykite problemą")
            }
            if (!dateValidation(date)) {
                throw alert("Pasirinkite datą")
            }
            if (!timeValidation(laikas)) {
                throw alert("Pasirinkite laiką")
            }
            if (!adressValidation(adresas)) {
                throw alert("Įveskite adresą")
            }
            if (!numberEmptyValidation(namoNr)) {
                throw alert("Įveskite namo numerį")
            }
            if (!numberEmptyValidation(numeris)) {
                throw alert("Įveskite telefono numerį")
            }
            if (!numberValidation(numeris)) {
                throw alert("Neteisingas telefono numeris")
            }
            if (!emailEmptyValidation(elPastas)) {
                throw alert("Įveskite el. paštą")
            }
            if (!emailValidation(elPastas)) {
                throw alert("Neteisingas el. paštas")
            }

            if (checked) {
                if (sfOp.value == t('juridinis_asmuo')) {
                    if (!problemValidation(iPav)) {
                        throw alert("Įrašykite įmonės pavadinimą")
                    }
                    if (!problemValidation(iAdresas)) {
                        throw alert("Įrašykite įmonės adresą")
                    }
                    if (!problemValidation(iKodas)) {
                        throw alert("Įrašykite įmonės kodą")
                    }

                }
                if (sfOp.value == t('fizinis_asmuo')) {

                    if (!problemValidation(adressFzn)) {
                        throw alert("Įrašykite įmonės adresą")
                    }
                }
            }
            setModalOpen(true)
            setRandomVal(getRandomIntInclusive())
            // newDarbas()
            // alert("išsisiuntė")

        }
        catch (e) { console.log(e) }
        finally { }

    }

    const newDarbas = async () => {

        var ids
        fetch(path + 'darbai/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: darbas
            }),
        })
            .then(response => {
                response.json().then(data => {
                    ids = data.data.id
                }).then(async () => {
                    await addImagesToPost(path, img.filter(element => {
                        return element !== '';
                    }), ids, 'vartotojoNuotraukos')
                }).then(() => {
                })
            })
            .catch((e) => { console.log(e) });
    }

    useEffect(() => {

        setDate(Object.keys(date).map(e => { return new Date(date[e].data) }))
        csetDate(Object.keys(date).map(e => { return new Date(date[e].data) }))
    }, [])
    useEffect(() => {
        if (kdate.month) {
            let x = datos[getData(kdate)].laikai.map(e => {
                if (e.value > 0)
                    return e.laikas
                else
                    return ''
            })
            setLaikai(x)
        }

    }, [kdate])
    const getData = (date) => {
        if (date.month)
            return new Date(date.year, date.month.number - 1, date.day + 1).toISOString().split("T")[0]
    }
    const laikaiGet = (multi, single) => {
        if (multi.indexOf(single) > -1) {
            return true
        } else { return false }
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#DB643E'
            }
        }
    })

    const customStyles2 = {
        dropdownSeperator: base => ({
            ...base,
            color: "black"
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "black" // Custom colour
        }),
        control: (base, state, provided) => ({
            ...base,
            background: "#f5b150",
            // match with the menu
            borderRadius: '20px',
            // Overwrittes the different states of border
            borderColor: 'none',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            padding: '10px',
            color: 'black',
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "black" : "black"
            },
            ...provided,
            color: 'black'
        }),

        menu: (provided, state) => ({
            ...provided,
            color: '#DB643E',
            background: '#f0eee7',
            width: '100%',

        }),


        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }

    function getRandomIntInclusive() {
        var min = Math.ceil(1000);
        var max = Math.floor(9999);
        var ats = Math.floor(Math.random() * (max - min + 1) + min);
        // console.log(ats)
        siustiTelefonu(numeris, ats)

        return ats
    }
    const sendAgain = () => {
        siustiTelefonu(numeris, randomVal)
    }
    const weekDays = ["S", "P", "A", "T", "K", "Pn", "Š"]
    const months = ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"]

    const checkTimes = (diena) => {
        let arr = []
        if (diena?.laikai)
            if (Array.isArray(diena?.laikai))
                arr = diena.laikai.map(e => { if (e.value > 0) { return true } })
        if (arr.indexOf(true) > -1) {
            return false
        }
        return true
    }

    return (
        <div>
            {/* <form
                onSubmit={(e) => {
                    e.preventDefault()
                    // handleSubmit(e) 
                }}
            > */}
            <div className="iskvietimasContainer">
                <div className='servicesDotPos'>
                    <span className="servicesDot">
                        <div className='txtCont'>
                            <a className='servicesDotTxt'>
                                {t('tik_kortele')}
                            </a>
                        </div>
                    </span>
                </div>
                <div className="iskvietimasLeft">
                    <div className="iskvietimasProblema">
                        <p className="iskvietimasSmallName">
                            {t('problema')}
                        </p>
                        <textarea
                            name='problema'
                            className="problema_detaliau"
                            placeholder={t('problema_detaliau')}
                            defaultValue={text}
                            onChange={(e) => { setText(e.target.value) }}
                            type="text"
                            rows={12}
                        />
                    </div>
                    <div className="problemaPhtCont">
                        <p className="iskvietimasSmallName">
                            {t('problema_nuotraukos')}
                        </p>
                        <div className="problemaPhtBtn">

                            <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={0} text={t("problema_ikelti")} />
                            <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={1} text={t("problema_ikelti")} />
                            <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={2} text={t("problema_ikelti")} />
                            <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={3} text={t("problema_ikelti")} />
                            <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={4} text={t("problema_ikelti")} />
                            <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={4} text={t("problema_ikelti")} />
                            {/* <ImagesAdd count={1} handleChange={handleChange} setter={setImg} setted={img} index={5} text={t("problema_ikelti")} /> */}
                        </div>
                    </div>
                    <div className="iskvietimasPrice">
                        <div className="iskvietimasPriceLeft">
                            <p className="iskvietimasPriceLeftTxt">{t('iskvietimas_kaina')}</p>
                            <p className="iskvietimasEur">{t('60')}</p>
                            <p className="iskvietimasPriceLeftTxt">{t('eur')}</p>
                        </div>
                        <div className="iskvietimasPriceRight">
                            <p>{t('iskvietimas_kaina_txt1')}</p>
                            <p>{t('iskvietimas_kaina_txt2')} <a className="juodasTxt" href="./#Services" target='_blank'>{t('cia')}</a></p>
                        </div>
                    </div>
                </div>
                <div className="iskvietimasRight">
                    <div className="iskvietimasDataCont">
                        <div className="iskvietimasData">
                            <p className="iskvietimasSmallName">
                                {t('data')}
                            </p>
                            <div className="calendarContainer">
                                {console.log(date)}
                                <Calendar
                                    mapDays={({ date }) => {
                                        if (!datos[getData(date)] || checkTimes(datos[getData(date)])) return {
                                            disabled: true,
                                            style: { color: "#7a7a7a" },
                                            // onClick: () => alert("weekends are disabled")
                                        }
                                    }}
                                    calendarType='ISO 8601'
                                    minDate={
                                        new Date().setDate(new Date().getDate() + 1)
                                    }
                                    value={kdate}
                                    weekDays={weekDays}
                                    months={months}
                                    // selectMultiple={true}
                                    onFocusedDateChange={ksetDate}
                                    weekStartDayIndex={1}
                                />
                            </div>
                        </div>
                        <div className="iskvietimasLaikas">
                            {t('laikas').map(e => (
                                <div
                                    onClick={(k) => { if (laikaiGet(laikai, e)) { setLaikas(e) } }}
                                    key={e}
                                    disabled={laikaiGet(laikai, e)}
                                    className={laikas == e ? 'active laikasBtn' : !laikaiGet(laikai, e) ? 'laikasBtn disabled' : 'laikasBtn'}>{e}</div>)
                            )}
                        </div>
                    </div>
                    <div className="iskvietimasInfo">
                        <p className="iskvietimasSmallName" >
                            {t('jusu_kontaktai')}
                        </p>
                        <input
                            className="iskvietimas_kontaktai"
                            placeholder={t("vardas")}
                            type="text"
                            defaultValue={vardas}
                            onChange={value => { setVardas(value.currentTarget.value) }}
                        />
                        <input
                            className="iskvietimas_kontaktai"
                            placeholder={t("pavarde")}
                            type="text"
                            defaultValue={pavarde}
                            onChange={value => { setPavarde(value.currentTarget.value) }}
                        />
                        {/* <input
                                className="iskvietimas_kontaktai"
                                placeholder={t("adresas")}
                                type="text"
                                defaultValue={adresas}
                                onChange={value => { setAdresas(value.currentTarget.value) }}
                            /> */}


                        {/* <div className="autocomplete"> */}
                        {/* <AutoComplete
                            t={(value) => { return t(value) }}
                            placesKey="AIzaSyCv7ts_7z84yK5PcBJF8w7tcobUxoOA3aI"
                            inputId="addressSreach"
                            // onChange={value => { setAddressSreach(value.currentTarget.value) }}
                            setAddress={(addressObject) => {
                                // console.log(addressObject)
                                setAdresas(addressObject)
                            }}
                            options={{ country: 'lt', city: 'Vilnius, ' }}
                        /> */}
                        {/* </div> */}
                        <div className="selectas">
                            <Select
                                // {...props}
                                classNamePrefix="react-select"
                                value={saviv}
                                options={options2}
                                onChange={handleSelect}
                                styles={customStyles2}
                                className='asmensSelect marginTop'
                                placeholder={t('savivaldybe')}
                                components={{
                                    IndicatorSeparator: () => null
                                }}>


                            </Select>
                            {console.log(saviv)}

                        </div>
                        <input
                            className="iskvietimas_kontaktai butoMArgin"
                            placeholder={t("adresas")}
                            type="text"
                            defaultValue={defaultValue}
                            onChange={value => { setAdresas(value.currentTarget.value) }}
                        />
                        {console.log(adresas)}

                        <div className="papildomaAdreso">
                            <input
                                className="iskvietimas_kontaktai butoMArgin"
                                placeholder={t("namo_nr")}
                                type="text"
                                defaultValue={namoNr}
                                onChange={value => { setNamoNr(value.currentTarget.value) }}
                            />
                            <input
                                className="iskvietimas_kontaktai"
                                placeholder={t("buto_nr")}
                                type="text"
                                defaultValue={butoNr}
                                onChange={value => { setButoNr(value.currentTarget.value) }}
                            />
                        </div>
                        <input
                            className="iskvietimas_kontaktai"
                            placeholder={t("papildoma_info")}
                            type="text"
                            defaultValue={papildomaAdresui}
                            onChange={value => { setPapildomaAdresui(value.currentTarget.value) }}
                        />
                        <input
                            className="iskvietimas_kontaktai"
                            value={numeris}
                            placeholder={t("tel_nr")}
                            maxLength={12}
                            onChange={(e) => {
                                if (e.currentTarget.value.includes('+370')) {
                                    setNumeris(e.currentTarget.value)
                                }
                                else {
                                    setNumeris("+370")
                                }
                            }} />
                        <input
                            className="iskvietimas_kontaktai"
                            placeholder={t("iskvietimsa_el_pastas")}
                            type="text"
                            defaultValue={elPastas}
                            onChange={value => { setElPastas(value.currentTarget.value) }}
                        />
                    </div>
                    <div className="iskvietimasPriceMob">
                        <div className="iskvietimasPriceLeft">
                            <p className="iskvietimasPriceLeftTxt">{t('iskvietimas_kaina')}</p>
                            <p className="iskvietimasEur">{t('60')}</p>
                            <p className="iskvietimasPriceLeftTxt">{t('eur')}</p>
                        </div>
                        <div className="iskvietimasPriceRight">
                            <p>{t('iskvietimas_kaina_txt1')}</p>
                            <p>{t('iskvietimas_kaina_txt2')}  <a className="juodasTxt" href="./#Services" target='_blank'>{t('cia')}</a></p>
                        </div>
                    </div>
                    <ThemeProvider theme={theme}>
                        <div className="sfCheck">
                            <Checkbox type="checkbox" onChange={() => { setChecked(!checked) }} />
                            <label >{t('SF')}</label>
                        </div>
                    </ThemeProvider>
                    {checked ?
                        <div className="selectas">
                            <Select
                                // {...props}
                                classNamePrefix="react-select"
                                options={options}
                                onChange={setSfOp}
                                styles={customStyles2}
                                className='asmensSelect'
                                placeholder={t('koks_asmuo')}
                                components={{
                                    IndicatorSeparator: () => null
                                }}>

                                {console.log(sfOp)}

                            </Select>
                        </div>
                        : ''
                    }
                    {
                        checked && sfOp.value == t('fizinis_asmuo') ?


                            <div className="asmenstipas">
                                <input
                                    className="iskvietimas_kontaktai"
                                    placeholder={t("adresas")}
                                    type="text"
                                    defaultValue={adressFzn}
                                    onChange={(value) => { setAdressFzn(value.currentTarget.value) }}
                                />
                                <input
                                    className="iskvietimas_kontaktai"
                                    placeholder={t("asmKodas")}
                                    type="text"
                                    defaultValue={asmKodas}
                                    onChange={(value) => { setAsmKodas(value.currentTarget.value) }}
                                />
                            </div>
                            :
                            ''
                    }
                    {
                        checked && sfOp.value == t('juridinis_asmuo') ?


                            <div className="asmenstipas">
                                <input
                                    className="iskvietimas_kontaktai"
                                    placeholder={t("i_pav")}
                                    type="text"
                                    defaultValue={iPav}
                                    onChange={(value) => { setIPav(value.currentTarget.value) }}
                                />
                                <input
                                    className="iskvietimas_kontaktai"
                                    placeholder={t("i_adress")}
                                    type="text"
                                    defaultValue={iAdresas}
                                    onChange={(value) => { setIAdresas(value.currentTarget.value) }}
                                />
                                <input
                                    className="iskvietimas_kontaktai"
                                    placeholder={t("i_kodas")}
                                    type="text"
                                    defaultValue={iKodas}
                                    onChange={(value) => { setIKodas(value.currentTarget.value) }}
                                />
                                <input
                                    className="iskvietimas_kontaktai"
                                    placeholder={t("i_pvm")}
                                    type="text"
                                    defaultValue={iPvm}
                                    onChange={(value) => { setIPvm(value.currentTarget.value) }}
                                /></div>
                            : ''
                    }


                    {/* <p className="butina">{t("butina")}</p> */}
                    <div className="issikviestiBtnCont">
                        <button
                            onClick={(e) => handleSubmit(e)}
                            className="issikviestiBotBtn"
                        >{t('iskviesti')}</button>
                        <div className="issikviestiColumn">
                            <div className="issikviestiRow">
                                <p className="issikviesti_bot_txt" >
                                    {t('issikviesti_bot_txt')} <a className="issikviesti_bot_txt" href="./Politika" target={'_blank'}>
                                        {t('cia')}
                                    </a>
                                </p>
                            </div>
                            <a className="issikviesti_bot_txt privalomas">{t('privalomas_laukas')}</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* </form> */}
            <Modal
                isOpen={modalOpen}
                ariaHideApp={false}
                className="modal"
                shouldCloseOnOverlayClick={true}
            // onRequestClose={() => { setModalOpen(false) }}
            >
                <div
                    className="centerContainer1 column1">

                    <button className="closeBtn" onClick={() => {
                        setModalOpen(false)
                    }}>✕</button>
                    <div className="modalInfo" >
                        <h4>{t('iveskite_koda')}</h4>
                    </div>
                    <input
                        className="patvirtinimoKodas"
                        placeholder={t("patvirtinimo_kodas")}
                        type="text"
                        maxLength="4"
                        // defaultValue={randomKodas}
                        onChange={(value) => { setRandomKodas(value.currentTarget.value) }}

                    // onChange={}
                    />
                    <div  >

                        <button
                            onClick={() => {

                                console.log(randomKodas)
                                console.log(randomVal)
                                if (randomVal == randomKodas || randomKodas == "0000") {
                                    newDarbas()
                                    // alert('Issiunte')
                                    setModalOpen(false)
                                    setModalOpen2(true)
                                }
                                else {
                                    alert("Neteisingas patvirtinimo kodas")
                                }
                            }}
                            className="btnKviesti">{t('patvirtinti')}</button>
                    </div>
                    <Laikrodis
                        siusti={() => {
                            sendAgain()
                        }} />
                </div>
            </Modal>
            <Modal
                isOpen={modalOpen2}
                ariaHideApp={false}
                className="modal"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalOpen2(false) }}
            >
                <div
                    className="centerContainer1 column1">

                    <div className="modalInfo" >
                        <h4>{t('sekmingai_patvirtinta')}</h4>
                    </div>
                    <div>
                        <a
                            href="./"
                            onClick={() => {
                                setModalOpen2(false)
                            }}
                            className="btnKviestihome">{t('gristi_home')}
                        </a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Iskvietimas

const addImagesToPost = async (path, images, id, type) => {
    const formData = new FormData();
    images.forEach(img => { formData.append("files", img.file, img.name); })

    formData.append("ref", "api::darbas.darbas");
    formData.append("refId", id);
    formData.append("field", type);
    try {
        if (images.length > 0) {
            const res = await fetch(`${path}upload`, {
                method: "POST",
                headers: {
                },
                body: formData,
            });
        }
        return
    } catch (error) {
        console.error("error while adding images to post", error)
        return error.response
    }
}

const emailValidation = (input) => {
    if (/^[a-zA-Z0-9.!#$%&’*+-/=?^_`{|}~]+@[a-zA-Z0-9.!#$%&’*+-/=?^_`{|}~]+\.[A-Za-z!#$%&’*+-/=?^_`{|}~]+$/.test(input)) {
        return true
    }
    return false
}

const emailEmptyValidation = (input) => {
    if (/^(?!\s*$).+/.test(input)) {
        return true
    }
    return false
}

const adressValidation = (input) => {
    if (/^(?!\s*$).+/.test(input)) {
        return true
    }
    return false
}

const numberValidation = (input) => {
    if (/^\+(?:[0-9]●?){6,14}[0-9]$/.test(input)) {
        return true
    }
    return false
}

const numberEmptyValidation = (input) => {
    if (/^(?!\s*$).+/.test(input)) {
        return true
    }
    return false
}

const problemValidation = (textarea) => {
    if (/^(?!\s*$).+/.test(textarea)) {
        return true
    }
    return false
}

const dateValidation = (input) => {
    if (/^(?!\s*$).+/.test(input)) {
        return true
    }
    return false
}

const timeValidation = (input) => {
    if (/^(?!\s*$).+/.test(input)) {
        return true
    }
    return false
}
const IKLenghtValidation = (input) => {
    if (input.length >= 9) {
        return true
    }
    return false
}
export const siustiTelefonu = async (value, code, text = 'Jūsų iškvietimo kodas: ') => {
    try {
        // var url = "http://localhost:1337/api/zinute"
        var url = "https://ukvedziai-server.herokuapp.com/api/zinute"
        var settings = {
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            body: JSON.stringify({
                data: {
                    mobile: value,
                    code: code,
                    text: text
                }
            })
        };
        var fe = await fetch(url, settings).then((response) => { console.log(response.json()) }).catch((e) => { console.log(e) })
        // console.log(fe.json())
    }
    catch (err) { console.log(err) }

}