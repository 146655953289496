import { useTranslation } from "react-i18next";
// import home_ukv from '../assets/home_ukv.png';
import Home_pav from '../components/Home_pav.js'
import UkvedziaiPng from '../assets/ukvedziaiPng.png';


const GautasIskvietimas = () => {
    const { t, i18n } = useTranslation();



    return (
        <div className="centerContainer">
            <Home_pav className='veidasMob'/>
            <div className="iskvietimasGautasRight">
                <div className="iskvietimasGautasContainer">
                    <p className="pav">
                        <div className="greiciau">
                            {t('iskvietima_gavom')}
                        </div>
                        <span className="SantechnikaPng"> <img src={UkvedziaiPng} /> </span>
                    </p>
                    <div className="avarineTxtCont">
                        <p className="iskvietimasGautastxt">{t('iskvietimo_numeris')}</p>
                        <p className="iskvietimasGautastxt">{t('vizito_priminimas')}</p>
                        <p className="iskvietimasGautastxt">{t('pries_vizita')}</p>
                        <p className="iskvietimasGautastxt">{t('iki_pasimatymo')}</p>
                    </div>
                    <span className="iskvietimasGautasDot">
                        <div className='txtCont'>
                            <a className='servicesDotTxt'>
                                {t('tik_kortele')}
                            </a>
                        </div>
                    </span>
                </div>
            </div>
        </div>

    )
}

export default GautasIskvietimas