import { useTranslation } from "react-i18next";
import Vinis from '../assets/vinis.png';
import Footerimg from '../assets/footer.png';
import Logo_u from '../assets/logo_u.png';
import Fb from '../assets/fb.png';
import Ig from '../assets/ig.png';
import { Link } from "react-router-dom";


const FooterMob = () => {

    const { t, i18n } = useTranslation();


    return (

        <div className="footerMob neGridFoot">
            {/* <Link key={t('paslaugos')} className="footerItem" to="/services">{t('paslaugos')}</Link> */}
            {/* <Link key={t('apie')} className="footerItem" to="/AboutMob">{t('apie_mus')}</Link> */}
            <div className="footerTelMob">
                <a href={'tel:' + t('irengimas_tel')}>{t("irengimas_tel")}</a>
            </div>
            <div className="footerTextMob">
                <a className='baltasTxt' href="mailto: jusu@ukvedziai.lt">{t("info_mail")}</a>
            </div>
            <div className="socIcon">
                <a href="https://www.facebook.com/Ukvedziai" target='_blank'><img src={Fb} alt="Fb" /></a>
                <a href='https://instagram.com/ukvedziai_?igshid=YmMyMTA2M2Y=' target='_blank'><img src={Ig} alt="Ig" /></a>
            </div>
        </div>

    )
}

export default FooterMob