import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { TaskContext } from "../AppStates";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Naujiena = () => {

    const [naujienos, setData] = useState([]);

    const { t, i18n } = useTranslation();
    const { path } = useContext(TaskContext);
    const { id } = useParams();
    const getNews = () => {
        var test = []
        axios
            .get(path + 'naujienos/' + id + '?populate=*&sort=createdAt:desc', {
            })
            .then((response) => {
                test = response.data.data
                setData(test)
                console.log(test)
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }
    useEffect(() => {
        getNews()
    }, [])

    return (
        <div>
            {
                naujienos.length == 0 ? (
                    <div>{t('naujienu_nera')}</div>
                ) : (
                    <div className='lentele naujienos'>
                        <div key={'naujiena-'} className="naujiena"
                            onClick={() => {

                            }}
                        >
                            {naujienos.attributes.showImg.data != undefined ?
                                <img key={'naujiena-img-'} src={naujienos.attributes.showImg.data.attributes.url} />
                                : ''}
                            <h2 key={'naujiena-title-'}>{naujienos.attributes.title}</h2>
                            <h3 key={'naujiena-subtitle-'}>{naujienos.attributes.subtitle}</h3>
                            <h3 key={'naujiena-date-'}>{naujienos.attributes.createdAt.split('T')[0]}</h3>
                                {console.log(naujienos)}

                            
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Naujiena

const makeDate = (date) => {
    // console.log(date)
    return new Date(date).toISOString().split('T')[0].replace('-', '.').replace('-', '.')
}