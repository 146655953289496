import { useTranslation } from "react-i18next";
import Kriaukle from '../assets/kriaukle.png';
import Vinis from '../assets/vinis.png';
import Garantija from '../assets/garantija.png';
import Wire from '../assets/wire.png';




const ServicesIrengimas = () => {

    const { t, i18n } = useTranslation();


    return (

        <div className="centerServicesContainer">
            <div className="serevicesIrLeft">
                <div className="contentContainer1">

                    <div className="irengimasName"> <h1>{t('irengimas')} </h1> </div>
                    <a className="serviceIrTxt"> {t('service_irengimas_txt')} </a>
                    <div className="servicePoints">
                        <div className="punktas"> <img className="vinis1" src={Vinis} alt="Vinis" /> <a className="vinisTxt">{t('service_irengimas_points')[0]}</a></div>
                        <div className="punktas"> <img className="vinis1" src={Vinis} alt="Vinis" /> <a className="vinisTxt">{t('service_irengimas_points')[1]}</a></div>
                        <div className="punktas"> <img className="vinis1" src={Vinis} alt="Vinis" /> <a className="vinisTxt">{t('service_irengimas_points')[2]}</a></div>
                        <div className="punktas"> <img className="vinis1" src={Vinis} alt="Vinis" /> <a className="vinisTxt">{t('service_irengimas_points')[3]}</a></div>
                        <div className="punktas"> <img className="vinis1" src={Vinis} alt="Vinis" /> <a className="vinisTxt">{t('service_irengimas_points')[4]}</a></div>
                        <div className="punktas"> <img className="vinis1" src={Vinis} alt="Vinis" /> <a className="vinisTxt">{t('service_irengimas_points')[5]}</a></div>
                    </div>
                    <div className="serevicesIrBot">
                        <div className="servicesIrBotLeft">
                            <a className="servicesMainTxt">
                                {t('remontas_ieskot')}
                            </a>
                            <div className="irengimasCall">
                            <a className="servicesMainTxtCall juodasTxt" href='mailto:jusu@ukvedziai.lt'>
                                    {t('irengimas_mail')}
                                </a>
                                <a className="servicesMainTxtCall juodasTxt" href={'tel:' +  t('irengimas_tel')}>
                                    {t('irengimas_tel')}
                                </a>
                                <a href={'tel:' +  t('irengimas_tel')} className="btnKviesti">
                                    {t('skambinti')}
                                </a>
                            </div>

                        </div>
                        <div className="servicesIrBotRight">
                            {t('kaina_priklauso')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="servicesIrRight pipes1">
                <img src={Kriaukle} alt="Kriaukle" />
            </div>
        </div>
    )
}

export default ServicesIrengimas