import { useTranslation } from "react-i18next";
import Vinis from '../assets/vinis.png';
import Rastai from '../assets/rastai.png';
import { useContext } from "react";
import { TaskContext } from "../AppStates";
import { useState } from "react";



const Naujienlaiskis = () => {

    const { t, i18n } = useTranslation();
    const { path } = useContext(TaskContext)
    const [email, setEmail] = useState('')
    const createNew = () => {
        // /api/subscibers
        console.log(email)
        fetch(path + 'subscibers/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: { email: email }
            }),
        })
            .then(response => {
                response.json().then(data => {
                    console.log(data)
                })
            }).catch((e) => { console.log(e) });
    }


    return (
        <div className="neGrid">

            <div className="naujienlaiskis">
                <img src={Rastai} alt="Rastai" />
                <div className="naujienlaiskisCont">
                    <input
                        className="elpastas"
                        placeholder={t("el_pastas")}
                        onChange={(e) => { setEmail(e.currentTarget.value) }}
                        type="text"
                    />
                    <button className="naujienlaiskisBtn" onClick={() => { createNew() }}>
                        <div className="naujienlaiskisBtnTxt">
                            {t("uzsisakyti_naujienlaiski")}
                        </div>
                        →
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Naujienlaiskis