import { useTranslation } from "react-i18next";
// import home_ukv from '../assets/home_ukv.png';
import Home_pav_sugedo from '../components/Home_pav_sugedo.js'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Iskvietimas from "./Iskvietimas.js";
import Dot from '../assets/Dot.png'
import Elektra from '../assets/elektra.png'
import sildymas from '../assets/sildymas.png'
import santechnika from '../assets/santechnika.png'
import kondencionavimas from '../assets/kondicionavimas.png'
import vedinimas from '../assets/vedinimas.png'
import iskvietimas from '../assets/iskvietimas.png'
import FooterMob from './FooterMob'
import { useContext } from "react";
import { TaskContext } from "../AppStates.js";
import ImportAll from "../functions/importAll.js";
import iks from '../assets/iks.png'

const Sugedo = () => {
    const fotkes = {
        Dot: Dot,
        Elektra: Elektra,
        Šildymas: sildymas,
        Santechnika: santechnika,
        Kondicionavimas: kondencionavimas,
        Vėdinimas: vedinimas,
        iskvietimas: iskvietimas
    }


    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { path } = useContext(TaskContext)
    const [headerText, setHeaderText] = useState(t('kas_sugedo'))
    const [kelias, setKelias] = useState({ kelias: [t('sutaisyti')] })
    const [sugedoDOt, setDot] = useState(Dot)
    const [kategorija, setKategorija] = useState('')
    const [grafikai, setGrafikai] = useState([])
    const [darbai, setDarbai] = useState([])
    const [dienos, setDienos] = useState([])
    const [uzimtos, setUzimtos] = useState([])
    const [galimos, setGalimos] = useState([])


    useEffect(() => {
        if (kelias.kelias.length == 2) {
            setHeaderText(t('tiksliau'))
            setDot(fotkes[kelias.current])
            setKategorija(kelias.current)
        } else if (kelias.kelias.length == 1) {
            setHeaderText(t('kas_sugedo'))
            setDot(fotkes.Dot)
        }
        else {
            setHeaderText(t('iskvietimas'))
            setDot(fotkes.iskvietimas)
        }
    }, [kelias])

    const get = async () => { await ImportAll(path, 'posts-report/?filters[kategorija][$containsi]=' + kategorija, setDarbai, 'users'); }
    const get2 = async () => { await ImportAll(path, 'darbo-dienos/?filters[Kategorija][$containsi]=' + t('kategorija').indexOf(kategorija), setGrafikai); }

    useEffect(() => {
        if (kategorija) {
            get()
            get2()
        }
    }, [kategorija])



    useEffect(() => {
        var g = []
        var d = []
        if (grafikai.length > 0) {
            grafikai.map(e => {
                var obj = e.attributes.datos
                g.push(Object.keys(obj).map(k => {
                    return {
                        data: k.replaceAll('/', '-'),
                        laikas: obj[k].laikas
                    }
                }))
            })
            setDienos(g)
        } else {
            setDienos([])
        }
        if (Array.isArray(darbai)) {
            darbai.map(e => {
                if (e)
                    d.push({
                        data: e.data.map(k => { return k }),
                        laikas: e.Laikas[0]
                    })
            })
            setUzimtos(d)
        }
        console.log('grafikai, darbai', grafikai, darbai)
    }, [grafikai, darbai])
    useEffect(() => {
        // setGalimos(suskaiciuotiDienas(uzimtos, dienos, t))
        // console.log(dienos, uzimtos)
        suskaiciuotiDienas(uzimtos, dienos, t).then((data) => {

            setGalimos(data)
            console.log('atsiunte', data)
        })
    }, [uzimtos, dienos])
    return (
        <div className="centerContainer sugedo ">
            <div className='kaire1'>

                <Home_pav_sugedo className='veidasMob' />
            </div>
            <div className="desine">

                <div className="sugedoRight sugedoFix">
                    <a href="/#home">
                        <img className="popUpClose" src={iks} alt='close' />
                    </a>
                    <div key={'sugedoUp'} className="modalNavigation">
                        <p className="modalNavigationTxt">
                            {kelias.kelias.map((e, i) => (
                                <span key={'span' + e}>
                                    {i != 0 ? " > " : ""}
                                    <a key={'link' + e} className="kelias"
                                        onClick={() => {

                                            setKelias(prevState => ({
                                                kelias:
                                                    i == 0 ? [kelias.kelias[0]] : i == 1 ? [kelias.kelias[0], kelias.kelias[1]] : prevState.kelias
                                                ,
                                                current: e
                                            }))
                                        }}
                                    >{e}</a>
                                </span>
                            ))}
                        </p>
                    </div>
                    <div className="sugedoContainer">
                        <div className="greiciau">
                            <p className="pav">
                                {headerText}
                            </p>
                            <span className="sugedoDot">
                                <img src={sugedoDOt} />
                            </span>
                        </div>
                        <div className="sugedoBtnCont">
                            {kelias.kelias.length == 1 ? t('gedimai').map((e, i) => (
                                <button key={e} onClick={() => {
                                    setKelias(prevState => ({
                                        kelias: [...prevState.kelias, e],
                                        current: e
                                    }))


                                }} className="sugedoBtn">{e}</button>
                            )
                            ) : kelias.kelias.length == 2 ? t(kelias.current).map((e, i) => (
                                <button key={e} onClick={() => {
                                    setKelias(prevState => ({
                                        kelias: [...prevState.kelias, e],
                                        current: e
                                    }))


                                }} className="sugedoBtn">{e}</button>
                            )) :
                                // galimos.length > 0 ?
                                <Iskvietimas datos={galimos} kategorija={kelias.kelias} />
                                //  : ''
                            }


                        </div>
                    </div>
                </div>
            </div>
            <FooterMob />
        </div >

    )
}

export default Sugedo

const suskaiciuotiDienas = async (uzimtos, dienos, t) => {
    var suskaiciuotos = {}
    dienos.map(grafikai => {
        grafikai.map(diena => {
            if (!suskaiciuotos[diena.data]) {
                suskaiciuotos[diena.data] = {
                    data: diena.data,
                    laikai: t('laikas').map((e, i) => {
                        return {
                            value: 0,
                            laikas: e,
                            add: () => { suskaiciuotos[diena.data].laikai[i].value += 2 }
                        }
                    })
                }
            }

            suskaiciuotos[diena.data].laikai.map((l, i) => {//data set
                // diena.laikas.map((e) => {//laikas 
                if (diena.laikas.indexOf(l.laikas) != -1) {
                    l.add()
                }

                // })
            })
        }
        )

    })
    uzimtos.map(darbas => {
        if (suskaiciuotos[darbas.data])
            suskaiciuotos[darbas.data].laikai.map(l => {
                if (l.laikas == darbas.laikas) {
                    l.value -= 1
                }
            })
    })
    return suskaiciuotos
}