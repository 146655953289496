

import axios from "axios";



const ImportAll = async (path, what, setter, flag) => {
    axios
        .get(path + what, {

        })
        .then((response) => {
            if (flag === 'users') { setter(response.data); }
            else {
                setter(response.data.data)
            }
            return
        })
        .catch((error) => {
            console.log('An error occurred:', error.response);
        });
}

export default ImportAll





export const getThem = async (path, what, info = {}, get, flag) => {
    try {
        var fe = await fetch(path + what, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                flag == 'users' ? info : {
                    data: info
                }
            ),
        })
        var jsonn = await fe.json()
        var errorsM = ''
        if (jsonn?.error?.status == 400) {
            console.log('js', jsonn, Object.keys(jsonn.error.details).length)
            if (Object.keys(jsonn.error.details).length > 0) {
                jsonn.error.details.errors.details.error.map(e => {
                    errorsM += (e.message + '\n')
                })
            }
            else errorsM = jsonn.error.message
            alert(errorsM)
        }
        get(jsonn)
        return jsonn
    }
    catch (e) {
        console.log(e);
        alert(e)
    }
    // finally { get() }
}
