import { useTranslation } from "react-i18next";
import Vinis from '../assets/vinis.png';
import Footerimg from '../assets/footer.png';
import Logo_u from '../assets/logo_u.png';
import Fb from '../assets/fb.png';
import Ig from '../assets/ig.png';
import { Link } from "react-router-dom";


const Footer = () => {

    const { t, i18n } = useTranslation();


    return (
        <div className="neGrid">
            <div className="footer" id="Footer">
                <div className="footerBackground">
                    <img src={Footerimg} alt="Footerimg" />
                </div>
                <div className="footerContent">
                    <div className="logoFooter">
                        <img src={Logo_u} alt="Logo_u" />
                    </div>
                    <div className="footerText">
                        <p>{t('uab')}</p>
                    </div>
                    <div className="footerText">
                        <a href={'tel:' + t('irengimas_tel')}>{t("irengimas_tel")}</a>
                    </div>
                    <div className="footerText">
                        <a href="mailto: jusu@ukvedziai.lt">{t("info_mail")}</a>
                    </div>
                    <a href="https://www.facebook.com/Ukvedziai" target='_blank'><img src={Fb} alt="Fb" /></a>
                    <a href='https://instagram.com/ukvedziai_?igshid=YmMyMTA2M2Y=' target='_blank'><img src={Ig} alt="Ig" /></a>
                </div>
            </div>
            {/* <div className="footerMob">
                <Link key={t('paslaugos')} className="footerItem" to="/services">{t('paslaugos')}</Link>
                <Link key={t('apie')} className="footerItem" to="/AboutMob">{t('apie_mus')}</Link>
                <div className="footerTelMob">
                    <p>{t("irengimas_tel")}</p>
                </div>
                <div className="footerTextMob">
                    <p>{t("info_mail")}</p>
                </div>
                <div className="socIcon">
                    <img src={Fb} alt="Fb" />
                    <img src={Ig} alt="Ig" />
                </div>
            </div> */}
        </div>
    )
}

export default Footer