import { useTranslation } from "react-i18next";
// import home_ukv from '../assets/home_ukv.png';
import Home_pav from '../components/Home_pav.js'
import iks from '../assets/iks2.png'


const Irengimas = () => {
    const { t, i18n } = useTranslation();



    return (
        <div className="centerContainer sugedo">
            <div className="kaire1">

            <Home_pav className='veidasMob'/>
            </div>
            <div className="desine">

            <div className="irengimasRight">
                <a href="/#home">
                    <img className="popUpClose" src={iks} alt='close' />
                </a>
                <div className="irengimasContainer">
                    <div className="pav">
                        <div className="greiciau">
                            {t('irengimas')}
                        </div>
                        <span className="dot"></span>
                    </div>
                    <div className="irengimasTxtCont">
                        <p className="irengimasTxt">{t('irengimas_txt')}</p>
                    </div>
                    <a className="irengimasTel" href={'tel:' + t('irengimas_tel')}>{t('irengimas_tel')}</a>
                    <a className="irengimasBtn" href="./#Services">{t('irengimas_btn')}</a>
                </div>
            </div>
            </div>
        </div>

    )
}

export default Irengimas