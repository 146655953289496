
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TaskContext } from "./AppStates";
import Home from "./routes/Home";
import About from "./routes/About";
import Contacts from "./routes/Contacts";
import News from "./routes/News";
import Services from "./routes/Services";
import Avarine from "./routes/Avarine";
import Home_pav from "./components/Home_pav";
import Home_pav_sugedo from "./components/Home_pav_sugedo";
import Irengimas from "./routes/Irengimas";
import Sugedo from "./routes/Sugedo";
// import Santechnika from "./routes/Sugedo/Santechnika";
// import Elektra from "./routes/Sugedo/Elektra";
// import Kondicionavimas from "./routes/Sugedo/Kondicionavimas";
// import Sildymas from "./routes/Sugedo/Sildymas";
// import Vedinimas from "./routes/Sugedo/Vedinimas";
import ServicesIrengimas from "./routes/ServicesIrengimas";
import GarantijaServ from "./routes/GarantijaServ";
import Naujienlaiskis from "./routes/Naujienlaiskis";
import Footer from "./routes/Footer";
import AboutMob from "./routes/AboutMob";
import FooterMob from "./routes/FooterMob";
import Iskvietimas from "./routes/Iskvietimas";
import Archyvas from "./routes/Archyvas";
import Naujiena from "./routes/Naujiena";
import GautasIskvietimas from "./routes/GautasIskvietimas";
import IskvietimasAtsauktas from "./routes/IskvietimasAtsauktas";
import Politika from "./routes/Politika";
import ManoUzsakymas from "./routes/ManoUzsakymas";

const Navigation = () => {


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Contacts" element={<Contacts />} />
      {/* <Route path="/News" element={<News />} /> */}
      <Route path="/Services" element={<Services />} />
      <Route path="/Avarine" element={<Avarine />} />
      <Route path="/Home_pav" element={<Home_pav />} />
      <Route path="/Home_pav_sugedo" element={<Home_pav_sugedo />} />
      <Route path="/Irengimas" element={<Irengimas />} />
      <Route path="/Sugedo" element={<Sugedo />} />
      {/* <Route path="/Santechnika" element={<Santechnika />} />
      <Route path="/Elektra" element={<Elektra />} />
      <Route path="/Kondicionavimas" element={<Kondicionavimas />} />
      <Route path="/Sildymas" element={<Sildymas />} />
      <Route path="/Vedinimas" element={<Vedinimas />} /> */}
      <Route path="/ServicesIrengimas" element={<ServicesIrengimas />} />
      <Route path="/GarantijaServ" element={<GarantijaServ />} />
      <Route path="/Naujienlaiskis" element={<Naujienlaiskis />} />
      <Route path="/Footer" element={<Footer />} />
      <Route path="/AboutMob" element={<AboutMob />} />
      <Route path="/FooterMob" element={<FooterMob />} />
      <Route path="/Iskvietimas" element={<Iskvietimas />} />
      <Route path="/Archyvas/:id" element={<Archyvas />} />
      <Route path="/Archyvas" element={<Archyvas />} />
      <Route path="/Naujiena/:id" element={<Naujiena />} />
      <Route path="/GautasIskvietimas/" element={<GautasIskvietimas />} />
      <Route path="/IskvietimasAtsauktas/" element={<IskvietimasAtsauktas />} />
      <Route path="/Politika/" element={<Politika />} />
      <Route path="/ManoUzsakymas/:id" element={<ManoUzsakymas />} />








    </Routes>

  )
}
export default Navigation
