import home_ukv from '../assets/home_ukv.png';
import { useTranslation } from "react-i18next";


const Home_pav = (props) => {

    const { t, i18n } = useTranslation();


    return (
        <div className={props.className + ' paveikslelis' }>
            <div className='kasAtsitikoMb'>
                {t('uzklupo_problema')}
            </div>
            <div className="homeLeftPav">

                <div className='pavCont'>
                    <p className='mainPavTxt'>
                        {t('prof')}
                    </p>
                    <img className="mainPav" src={home_ukv} />
                    {/* <div className='eyes'>
                        <div className='eye'>
                            
                        </div>
                    </div> */}
                    <div className='mainDotPos'>
                        <span className="main_dot">
                            <div className='txtCont'>
                                <p className='dotTxt'>
                                    {t('vilniuje')}
                                </p>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Home_pav