import { t } from 'i18next';
import React, { useEffect } from 'react';
import ImageUploading from 'react-images-uploading';

export function ImagesAdd(props) {
  const [images, setImages] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const maxNumber = props.count;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    let arr = props.setted
    arr[props.index] = imageList[0]
    setImages(imageList);
    props.setter(arr)
    console.log(arr)
  };
  // useEffect(()=>{
  // setImages(props.imgArr)
  // 
  // },[])
  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper problemaBtn">
            {props.count < 2 && imageList.length <= 0 ?
              <button
                className=' imgFull '
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                {props.text}
              </button>
              : ''}

            &nbsp;
            {props.count > 1 ?
              <button onClick={onImageRemoveAll}>Remove all images</button>
              : ''}
            {imageList.map((image, index) => (
              <div key={index} className="image-item imgAdd" >
                {/* <img src={image['data_url']} alt="" width="100" /> */}
                <div className='opens' onClick={() => {
                  setShow(true)
                }
                }>{image.file.name}
                </div>
                <div className='imgModal' style={show ? { display: 'flex' } : { display: 'none' }}
                  onClick={(e) => {
                    e.preventDefault()

                    setShow(false)
                  }}
                >
                  <div className='imgRemoveCont'>
                    <div className='removes' onClick={(e) => {
                      e.preventDefault()
                      onImageUpdate(index)
                    }}> {t('pakeisti')}</div>
                    <div className='removes' onClick={() => onImageRemove(index)}>{t('istrinti')}</div>
                  </div>
                  <img className='img' src={image.data_url} />
                </div>
                <div className='removes1' onClick={() => onImageRemove(index)}>⨉</div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}