export const lt = {

    translations: {

        apie: 'Apie',
        apie_mus: 'Apie mus',
        paslaugos: 'Paslaugos',
        menesio_naujiena: 'Rekomendacijos',
        kontaktai: 'Kontaktai',
        uzklupo_problema: 'Kas atsitiko?',
        sutaisyti: 'Reikia sutaisyti',
        isirengti: 'Noriu įsirengti',
        avarine: 'Greičiau avarinę!',
        sutaisyti_po: 'Remontuojame butų ir privačių namų santechnikos, elektros, šildymo, kondicionavimo bei vėdinimo sistemų įrangą',
        isirengti_po: 'Projektuojame, parenkame ir montuojame butų bei privačių namų santechnikos, šildymo, kondicionavimo ir vėdinimo sistemų įrangą',
        avarine_po: 'Patys tokios pagalbos, deja, neteikiame, bet kontaktais galime pasidalinti:',
        avarine_tel: 'tel. 1355',
        testi: 'Tęsti',
        avarine_txt1: 'Skambinkite Vilniaus miesto avarinei tarnybai',
        avarine_txt2: '(Avarija, UAB)',
        avarine_txt3: 'Nors už tarnybos paslaugų kokybę neatsakome, tikimės, kad avarija jūsų namuose bus likviduota greitai ir neskausmingai',
        skambinti: 'Skambinti',
        vilniuje: 'Šiuo metu tik Vilniaus apskrityje',
        prof: 'Profesionalūs namų prižiūrėtojai',
        remontas: 'Remontas',
        remontas_txt: 'Remontuojame viską, kas užsikimšo, „išmušė“, nešyla, laša, netraukia ar kitaip sugedo jūsų namų santechnikos, elektros, šildymo, kondicionavimo ar vėdinimo sistemose.',
        remontas_ieskot: 'Kaip tik mūsų ieškote?',
        iskviesti: 'Išsikviesti',
        eur: 'Eur/val.',
        fiksuotas: 'Visus savo remonto darbus vertiname vienu fiksuotu valandiniu įkainiu.',
        neiskaiciuota: 'Į valandinį įkainį remontui reikalingų medžiagų kaina neįskaičiuota.',
        bendra_samata: 'Bendrą remonto darbų ir medžiagų sąmatą paskaičiuosime jau atvykę į vietą - tiksliai išsiaiškinę problemą ir suplanavę konkrečius remonto darbus.',
        atsisakyti: 'Jei dėl kokių nors priežaščių po ūkvedžio vizito mūsų paslaugų atsisakytumėte, už iškvietimą vistiek būtų imamas minimalus 60 eurų mokestis.',
        irengimas: 'Įrengimas',
        irengimas_txt: 'Įrengimo darbus visada pradedame nuo pokalbio su namų šeimininkais. Tiesiog paskambinkite mums, ir viską sutarsime. Jei pasirodys, kad pokalbio telefonu neužtenka - suplanuosime mūsų vizitą į vietą.',
        irengimas_tel: '+370 670 00858',
        irengimas_mail: 'jusu@ukvedziai.lt',
        irengimas_btn: 'Apie mūsų paslaugas plačiau',
        kas_sugedo: 'Kas sugedo?',
        tiksliau: 'O tiksliau?',
        service_irengimas_txt: 'Galime jūsų naujuose namuose pajungti seną skalbimo mašiną. Arba „nuo nulio“ suprojektuoti, parinkti ir sumontuoti pačią moderniausią buto ar individualaus namo inžinerinių sistemų įrangą:',
        tik_kortele: 'Dėmesio! Atsiskaitymas tik banko kortele',
        kaina_priklauso: 'Kaina priklauso nuo jūsų pageidavimų, darbų apimties, techninių ir daugybės kitokių aplinkybių. Tik išsiaiškinę visus šiuos niuansus galėsime pasakyti konkrečią įrengimo darbų jūsų namuose kainą.',
        garantija: 'Garantija',
        garantija_txt: 'Suteikti 3 mėnesių garantiją net ir smulkiausiems savo darbams - mažiausia, ką galime padaryti. Neabejotinos savo paslaugų kokybės siekiame visais būdais:',
        menesio_naujiena: 'Rekomendacijos',
        priziuretojai: 'Profesionalūs namų prižiūrėtojai',
        about_txt1: 'Teoriškai namai nuostabu, bet praktiškai – kriauklės kartais kemšas. Elektra dingsta, boileriai genda, radiatoriai šąla – namuose kartais atsiranda reikalų, kuriems spręsti jūs neturite nei laiko, nei supratimo, nei – būkim atviri – noro. Užtat visko turime mes – skirtingų sričių meistrų komanda.',
        about_txt2: 'Kur pakabinti paveikslą, gal ir nepatarsime, bet pasirūpinti jūsų namais praktiškai – mums ne problema.',
        el_pastas: 'Jūsų el. pašto adresas',
        uzsisakyti_naujienlaiski: 'Užsisakyti naujienlaiškį ',
        archyvas: "Archyvas",
        info_mail: 'jusu@ukvedziai.lt',
        uab: 'UAB Ūkvedžiai',
        iskvietimas: 'Iškvietimas',
        problema: 'Aprašykite problemą*',
        problema_detaliau: 'Detaliau aprašykite, kas atsitiko',
        problema_nuotraukos: 'Problemą atspindinčios nuotraukos',
        problema_ikelti: 'Įkelti',
        problema_prideta: 'Pridėta',
        adresas: 'Adresas',
        tel_nr: 'Telefono numeris',
        iskvietimsa_el_pastas: 'El. paštas',
        jusu_kontaktai: 'Jūsų kontaktai*',
        iskvietimas_kaina: 'Kaina',
        iskvietimas_kaina_txt1: 'Minimalus (iškvietimo) mokestis 60 eurų.',
        iskvietimas_kaina_txt2: 'Apie mūsų paslaugų kainas plačiau skaitykite ',
        issikviesti_bot_txt: 'Paspausdami mygtuką „Išsikviesti“, patvirtinate, kad sutinkate su mūsų paslaugų teikimo sąlygomis. Su jomis susipažinti kviečiame ',

        data: 'Data ir laikas*',
        iskvietima_gavom: 'Iškvietimą gavome!',
        iskvietimo_numeris: 'Jūsų iškvietimo numeris: ',
        vizito_priminimas: 'Priminimą apie planuojamą vizitą išsiuntėme jūsų nurodyto el. pašto adresu.',
        pries_vizita: 'Dieną prieš mūsų vizitą apie tai jums darkart priminsime trumpąja žinute. Matydama, kad reikia papildomos informacijos, būtinai iš anksto susisieksime.',
        iki_pasimatymo: 'Iki pasimatymo sutartu laiku!',
        iskvietimas_atsauktas: 'Iškvietimas atšauktas!',
        iskvietimas_atsauktas_txt: 'Jūsų iškvietimas atšauktas.',
        sekmes_namuose: 'Sėkmės namuose!',
        pakeisti: 'Pakeisti',
        istrinti: 'Ištrinti',
        laikas: [
            "8:00  —  11:00",
            "11:00  —  14:00",
            "14:00  —  17:00",
            "17:00  —  20:00",
        ],
        garantija_points: [
            "Kruopščiai atrinkinėjame personalą",
            "Nuolat tobuliname meistrų  kvalifikaciją",
            "Bendradarbiaujame su viena didžiausių inžinerinių sistemų projektavimo bei montavimo įmonių Lietuvoje – ",
            "ENERGOKOMPLEKTAS"
        ],
        service_irengimas_points: [
            "santechninius prietaisus",
            "radiatorinio ar grindinio šildymo įrenginius",
            "šilumos siurblių (kondicionierių) vidaus ir išorės blokus",
            "rekuperatorių ir kitus vėdinimo sistemos įrenginius",
            "dujines ir geotermines katilines bei šilumos punktus",
            "kitą santechnikos, šildymo, kondicionavimo ir vėdinimo įrangą",
        ],
        gedimai: [
            "Santechnika",
            "Elektra",
            "Šildymas",
            "Kondicionavimas",
            "Vėdinimas"
        ],
        Santechnika: [
            "Laša / bėga vanduo",
            "Užsikimšo kanalizacija",
            "Kita problema",
        ],
        Kondicionavimas: [
            "Kondicionierius nešaldo",
            "Iš kondicionieriaus laša vanduo",
            "Kita problema",
        ],
        Elektra: [
            "Dingo elektra",
            "Nedega lempa / neveikia jungiklis",
            "Sutrikimai elektros skyde",
            "Kita problema",
        ],
        Šildymas: [
            "Nešyla radiatorius",
            "Neveikia grindinis šildymas",
            "Neveikia šildymo valdikliai",
            "Katilinėje laša vanduo",
            "Sulūžo įranga",
            "Valdiklis rodo klaidą ('error')",
            "Kita problema",
        ],
        Vėdinimas: [
            "Per mažas oro srautas",
            "Įranga veikia per garsiai",
            "Valdiklis rodo klaidą ('error')",
            "Neveikia rekuperatorius / vėdinimo kamera",
            "Kita problema",
        ],
        vardas: 'Vardas',
        pavarde: 'Pavardė',
        privalomas_laukas: '* Privaloma',
        i_pvm: 'PVM kodas',
        i_kodas: 'Įmonės kodas',
        i_adress: 'Įmonės adresas',
        i_pav: 'Įmonės pavadinimas',
        SF: 'Reikalinga sąskaita faktūra',
        butina: 'Ši informacija būtina',
        kategorija: [
            "Santechnika",
            "Elektra",
            "Šildymas",
            "Kondicionavimas",
            "Vėdinimas"
        ],
        iveskite_koda: 'Sekundėlę: dar paprašysime įvesti kodą, kurį netrukus gausite SMS',
        patvirtinimo_kodas: 'Patvirtinimo kodas',
        sekmingai_patvirtinta: 'Iškvietimą gavome! Jūsų iškvietimo numerį ir visą kitą informaciją netrukus jums atsiųsime elektroniniu paštu.',
        gristi_home: 'Atgal į pagrindinį puslapį',
        patvirtinti: 'Patvirtinti',
        privatumo_politika: 'Su Paslaugų teikimo taisyklėmis galite susipažinti ',
        cia: 'čia.',
        pp: 'Privatumo politika',
        pt: 'Paslaugų teikimo taisyklės',
        fizinis_asmuo: 'Fizinis asmuo',
        juridinis_asmuo: 'Juridinis asmuo',
        asmKodas: 'Asmens kodas',
        koks_asmuo: 'Teisinis statusas',
        naujienu_nera: 'Rekomendacijų nėra',
        buto_nr: 'Buto Nr.',
        namo_nr: 'Namo Nr.',
        papildoma_info: 'Lauko durų kodas ar kita papildoma informacija',
        Siusti_is_naujo: 'Siųsti iš naujo',
        ManoUzsakymas: 'Mano užsakymas',
        atsaukti: 'Atšaukti',
        buvo_atsauktas: 'Jūsų iškvietimas Nr.',
        buvo_atsauktas2: ' atšauktas. Sėkmės namuose!',
        susisiekti: 'Susisiekti',
        Patvirtinti: 'Patvirtinti',
        vilnius_r:'Vilniaus raj.',
        vilnius:'Vilnius',
        trakai_r:'Trakų raj.',
        savivaldybe:'Savivaldybė',
        daugiau: 'Daugiau',

    }
}
