
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TaskContext } from '../AppStates';
import { getThem } from '../functions/importAll';
import { siustiTelefonu } from './Iskvietimas';
const ManoUzsakymas = () => {
    const { id } = useParams();
    const { t } = useTranslation()
    const { path } = useContext(TaskContext)
    const [darbai, setDarbai] = useState([])
    const [darbai2, setDarbai2] = useState([])
    const [info, setinfo] = useState({})
    var [first, ...rest] = id.split('&')
    rest = rest.join('')
    const get = async () => { await getThem(path, 'darbo-duomenys/', { id: first, rest }, setDarbai, 'users',); }
    const get2 = async () => {
        await getThem(path, 'darbo-atmetimas/', { id: first, rest }, setDarbai2, 'users',)
        await get();

    }
    const get3 = async () => {
        await getThem(path, 'darbo-patvirtinimas/', { id: first, rest }, setDarbai2, 'users',)
        await get();
    }

    useEffect(() => {
        get()
        console.log(rest)
    }, [])
    useEffect(() => {
        setinfo(darbai[0])
    }, [darbai])
    useEffect(() => {
        console.log(darbai2)
    }, [darbai2])
    console.log(darbai)
    const arr = ['id', 'Laikas', 'data', 'vardas', 'pavarde', 'adress']
    return info && Object.keys(info).length > 0 ? (

        <div className='ManoUzsakymas'>
            {console.log(info)}
            <h1>{t('ManoUzsakymas')}</h1>
            <div className='infoLentele'>
                <div>
                    ID: {info.data[0].slice(2, 4) + '-' + info.id}
                </div>
                <div>
                    {t('vardas').replace('*', '')}: {info.vardas} {info.pavarde}
                </div>
                <div>
                    {t('data').replace('*', '')}: {info.data}
                </div>
                <div>
                    {t('Laikas').replace('*', '')}: {info.Laikas}
                </div>
            </div>
            {info.issiustasPriminimas && info.statusas == 'Priskirtas Nepatvirtintas' ?
                <div className='center manoUzsakymas'>
                    <input
                        onClick={(e) => { get3() }}
                        type={'button'}
                        className="issikviestiBotBtn"
                        value={t('Patvirtinti')} />
                    <a className="issikviestiBotBtn" href={'tel:' + t('irengimas_tel')}>{t('susisiekti') + ':' + t('irengimas_tel')}</a>
                </div>
                : info.statusas == 'Priskirtas Patvirtintas' ?
                    <div>{info.statusas}</div>
                    : info.statusas == 'Atšauktas' ?
                        <div className='center manoUzsakymas'>{t('buvo_atsauktas')} {info.data[0].slice(2, 4) + '-' + info.id} {t('buvo_atsauktas2')}</div>
                        : info.statusas != 'Atšauktas' ? (
                            <div className='center manoUzsakymas'>
                                <input
                                    onClick={(e) => { get2() }}
                                    type={'button'}
                                    className="atsauktiBotBtn primary btn"
                                    value={t('atsaukti')} />
                            </div>
                        ) : ''
            }



        </div >)
        : (
            <div ></div>
        )


}

export default ManoUzsakymas