import datetimeDifference from "datetime-difference";
import { t } from "i18next";
import { useEffect } from "react";
import { useState } from "react";


const Laikrodis = (props) => {
    const [ticking, setTicking] = useState(null)

    const Laikrodukas = () => {
        const time =
        {
            sec: 120,
            addSec: function () { return this.sec -= 1 },
            setSec: function () { return this.sec = 120 },
        }
        time.setSec()
        var tick = setInterval(() => {
            time.addSec()
            setTicking(time.sec)
            if (time.sec <= 0) {
                clearInterval(tick)
            }
        }, 1000)
    }
    useEffect(() => { Laikrodukas() }, [])
    console.log(setTicking)

    return (
        ticking > 0 ?
            <div className="laikrodisTick">{ticking}</div>
            :
            <button className="btnKviesti" onClick={() => { Laikrodukas(); props.siusti() }}> {t('Siusti_is_naujo')}</button>
    )
}
export default Laikrodis

const makeTwo = (value) => {
    if (parseInt(value) < 10) { return '0' + value }
    else { return value }
}

const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
}