import { useTranslation } from "react-i18next";
// import home_ukv from '../assets/home_ukv.png';
import Home_pav from '../components/Home_pav.js'
import Iks from '../assets/iks.png';
import UkvedziaiPng from '../assets/ukvedziaiPng.png';




const IskvietimasAtsauktas = () => {
    const { t, i18n } = useTranslation();



    return (
        <div className="centerContainer">
            <Home_pav className='veidasMob'/>
            <div className="iskvietimasGautasRight">
                <div className="iskvietimasGautasContainer">
                    <p className="pav">
                        <div className="greiciau">
                            {t('iskvietimas_atsauktas')}
                        </div>
                        <span className="SantechnikaPng"> <img src={Iks} /> </span>
                    </p>
                    <div className="avarineTxtCont">
                        <p className="iskvietimasGautastxt">{t('iskvietimas_atsauktas_txt')}</p>
                        <p className="iskvietimasGautastxt">{t('sekmes_namuose')}</p>
                    </div>
                    <span className="IskvietimasAtsauktasPng"> <img src={UkvedziaiPng} /> </span>
                </div>
            </div>
        </div>

    )
}

export default IskvietimasAtsauktas