import { useTranslation } from "react-i18next";
// import home_ukv from '../assets/home_ukv.png';
import Home_pav from '../components/Home_pav.js'
import iks from '../assets/iks2.png'



const Avarine = () => {
    const { t, i18n } = useTranslation();



    return (
        <div className="centerContainer sugedo">
            <div className="kaire1">

                <Home_pav className='veidasMob' />
            </div>
            <div className="desine">

                <div className="avarineRight">
                    <a href="/#home">
                        <img className="popUpClose" src={iks} alt='close' />
                    </a>
                    <div className="avarineContainer">
                        <div className="pav">
                            <div className="greiciau">
                                {t('avarine')}
                            </div>
                            <span className="dot"></span>
                        </div>
                        <div className="avarineTxtCont">
                            <p className="txt avarineBeTarpoTxtBot">{t('avarine_txt1')}</p>
                            <p className="txt avarineBeTarpoTxtTop">{t('avarine_txt2')}</p>
                            <p className="txt">{t('avarine_txt3')}</p>
                        </div>
                        <a className="avarinteTel">{t('avarine_tel')}</a>
                        <a href="tel:1355" className="btnCall">{t('skambinti')}</a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Avarine