import { useTranslation } from "react-i18next";
import Namas from '../assets/namas.png';



const About = () => {

    const { t, i18n } = useTranslation();


    return (

        <div className="aboutContainer" id="About">

            <div className="pipes2" >
                <img src={Namas} alt="Namas" />
            <div className='aboutDotPos'>
                <span className='aboutDot'>
                    <p className='aboutDotTxt'>
                        {t('vilniuje')}
                    </p>
                </span>
            </div>
            </div>

            {/* <div className='aboutMobBtnCont'>
                <button className='aboutMobBtn'>{t('paslaugos')}</button>
            </div> */}

            <div className="aboutRight">
                <div className="contentContainerAbout">

                    <div className="aboutTxt">
                        <h1 className="aboutName">{t('priziuretojai')}</h1>
                        <p className="aboutTxt2">{t('about_txt1')}</p>
                        <p className="aboutTxt2">{t('about_txt2')}</p>

                    </div>
                </div>
            </div>

            <div className="pipes22" >
                <img src={Namas} alt="Namas" />
            </div>
        </div>
    )
}

export default About