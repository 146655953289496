import { useTranslation } from "react-i18next";
import Namas from '../assets/namas.png';
import Footer from "./Footer";
import FooterMob from "./FooterMob";




const AboutMob = () => {

    const { t, i18n } = useTranslation();


    return (

        <div className="aboutMobContainer">
            <div className='aboutDotPos'>
                <span className='aboutDot'>
                    <p className='aboutDotTxt'>
                        {t('vilniuje')}
                    </p>
                </span>
            </div>
            <div className='aboutMobBtnCont'>
                <button className='aboutMobBtn'>{t('paslaugos')}</button>
            </div>
            <div className="aboutMobTxtContainer">
                <div className="contentContainer">
                    <div className="aboutMobTxt">
                        <p className="aboutMobName">{t('priziuretojai')}</p>
                        <p>{t('about_txt1')}</p>
                        <p>{t('about_txt2')}</p>
                    </div>
                </div>
            </div>
            <div className="aboutRight">
                <div className="pipes1" >
                    <img src={Namas} alt="Namas" />
                </div>
            </div>
        </div>
    )
}

export default AboutMob