import { useRef, useState } from "react";
import { useSSR, useTranslation } from "react-i18next";


const Politika = () => {

	const { t, i18n } = useTranslation();
	const service = useRef(null);
	const [pp, setpp] = useState(false);
	const [pp1, setpp1] = useState(false);



	const AddedLIP = ({ text }) => {
		return (
			<li>
				<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>
					{text}
					<span className="Apple-converted-space">&nbsp;</span></p>
			</li>
		)
	}
	return (

		<div className="politikaCont">
			<div className="pp">
				<h3 className={pp ? 'opener open' : 'opener'} onClick={() => { setpp(!pp) }}>{t('PRIVATUMO POLITIKA')} </h3>
				<div className={pp ? ' pp ' : ' pp hidden '}>
					<div>
						<ol style={{ caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 'auto', textAlign: 'start', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', widows: 'auto', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none' }}>
							<li>
								<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>BENDROSIOS NUOSTATOS</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mums rūpi Jūsų privatumas ir Jūsų asmens duomenų saugumas, todėl parengėme šią privatumo politiką (toliau –<span className="Apple-converted-space">&nbsp;</span><strong>Privatumo politika</strong>), kurioje paaiškiname kaip mes tvarkome bei saugome Jūsų asmens duomenis, kokias Jūsų teises užtikriname, bei pateikiame kitą informaciją apie Jūsų asmens duomenų tvarkymą.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Šioje Privatumo politikoje naudojama sąvoka „asmens duomenys“ (toliau –<span className="Apple-converted-space">&nbsp;</span><strong>Asmens duomenys</strong>) reiškia bet kokią informaciją arba informacijos rinkinį, pagal kuriuos mes galime tiesiogiai arba netiesiogiai nustatyti Jūsų tapatybę, pavyzdžiui, pagal Jūsų vardą, pavardę, elektroninio pašto adresą, telefono numerį ir t.t.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Tvarkydami Asmens duomenis laikomės Bendrojo duomenų apsaugos reglamento Nr. 2016/679 (ES) (toliau –<span className="Apple-converted-space">&nbsp;</span><strong>BDAR</strong>) bei Lietuvos Respublikos teisės aktų reikalavimų, taip pat kontroliuojančių institucijų nurodymų.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Privatumo politika taikoma kai Jūs lankotės mūsų administruojamoje elektroninėje namų ūkio priežiūros paslaugų sistemoje „Ūkvedžiai“, pasiekiamoje adresu<span className="Apple-converted-space">&nbsp;</span><u>https://ukvedziai.lt/</u><span className="Apple-converted-space">&nbsp;</span>(toliau –<span className="Apple-converted-space">&nbsp;</span><strong>Platforma)</strong>,<span className="Apple-converted-space">&nbsp;</span>lankotės mūsų socialinių tinklų paskyrose Facebook, Instagram, LinkedIn, Youtube<span className="Apple-converted-space">&nbsp;</span>(toliau –<span className="Apple-converted-space">&nbsp;</span><strong>Socialinės paskyros</strong>) peržiūrite mūsų pateiktą informaciją, užsakote mūsų siūlomas paslaugas ir jomis naudojatės, susisiekiate su mumis telefonu, el. komunikacijos kanalais, domitės mūsų pasiūlymais ar kreipiatės kitais klausimais.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.11in', direction: 'ltr', backgroundColor: 'transparent' }}>Platformoje ir Socialinėse paskyrose gali būti nuorodų į išorines svetaines (tokias kaip, pavyzdžiui, avarinių tarnybų puslapiai). Ši Privatumo politika nėra taikoma per tokias nuorodas pasiekiamoms svetainėms. Prieš naršydami tokiose svetainėse ar jose pateikdami savo Asmens duomenis, peržiūrėkite jų privatumo politikas.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei užsakote ir gaunate mūsų paslaugas, lankotės Platformoje ar Socialinėse paskyrose, susisiekiate su mumis, prenumeruojate mūsų naujienas ar kreipiatės į mus kitais klausimais, mes laikome, kad Jūs perskaitėte ir sutikote su Privatumo politikos sąlygomis ir joje nurodytais Asmens duomenų naudojimo tikslais, būdais bei tvarka. Jei nesutinkate su Privatumo politika, Jūs negalite naudotis Platforma, Socialinėmis paskyromis, taip pat kreiptis į mus dėl mūsų paslaugų pasiūlymų.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Norime atkreipti dėmesį, jog ši Privatumo politika gali keistis, tad prašome Jūsų laikas nuo laiko apsilankyti Platformoje ir perskaityti skelbiamą naujausią Privatumo politikos versiją.</p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>KAS MES?</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_heading=h.30j0zll" /><span className="Apple-converted-space">&nbsp;</span>Mes esame<span className="Apple-converted-space">&nbsp;</span><strong>UAB „Ūkvedžiai“</strong>, Lietuvos Respublikoje įsteigta uždaroji akcinė bendrovė, juridinio asmens kodas 305588699, adresas Panerių g. 64, LT-03160 Vilnius, Lietuva, duomenys kaupiami ir tvarkomi VĮ „Registrų centras“ Juridinių asmenų registre (toliau –<span className="Apple-converted-space">&nbsp;</span><strong>Bendrovė</strong><span className="Apple-converted-space">&nbsp;</span>arba<span className="Apple-converted-space">&nbsp;</span><strong>mes</strong>).</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Mes valdome Platformą ir siūlydami bei teikdami paslaugas, vykdydami Bendrovės kasdienę veiklą ar teisės aktų reikalavimus, veikiame kaip Asmens duomenų valdytojas.</p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>KOKIUS JŪSŲ ASMENS DUOMENIS MES TVARKOME?</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes tvarkome Jūsų Asmens duomenis, gautus tokiais būdais:</p>
										<ol>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kai Jūs Asmens duomenis pateikiate mums, pavyzdžiui, registruojatės Platformoje, užsakote mūsų paslaugas, kitaip ja naudojatės, susisiekiate su mumis el. paštu, telefonu ir pan.;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kai mes surenkame Jūsų Asmens duomenis Jums naudojantis Platforma, Socialinėmis paskyromis, pavyzdžiui, paslaugų užsakymo istorija, Jūsų IP adresas, slapukai, apsilankymo Platformoje istorija, pasirinkimai, atidarytos URL nuorodos ir pan.;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kai Asmens duomenis mes gauname iš kitų asmenų, pavyzdžiui, kai informaciją gauname iš viešų registrų, valstybės ar vietos savivaldos institucijų ar įstaigų, mūsų partnerių, kitų trečiųjų asmenų, pavyzdžiui, mokėjimo įstaigų, apie atliktus mokėjimus ir pan.;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kai Jūsų asmens duomenis, Jūsų sutikimu, mums pateikia kiti asmenys, pavyzdžiui, kai nurodo Jus kaip kontaktinį ar galiotą asmenį ir pan.</p>
											</li>
										</ol>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Asmens duomenis mums teikiantis asmuo atsako už tokių Asmens duomenų teisingumą, išsamumą ir aktualumą, taip pat už asmens, kurio duomenys teikiami, sutikimą dėl duomenų perdavimo mums. Mes galime paprašyti patvirtinti, kad asmuo turi teisę teikti mums Asmens duomenis (pavyzdžiui, pildant paslaugų užsakymo ar registracijos formas). Esant poreikiui (pavyzdžiui, asmuo teiraujasi mūsų apie jo Asmens duomenų gavimą), mes nurodysime tokių duomenų teikėją.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><span className="Apple-converted-space">&nbsp;</span>Mes tvarkome Jūsų Asmens duomenis tokiais tikslais ir sąlygomis:</p>
										<table cellPadding={11} cellSpacing={0} width={1000}>
											<tbody>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Asmens duomenų tvarkymo tikslas</strong></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Tvarkomi Asmens duomenys</strong></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Asmens duomenų tvarkymo terminai</strong></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Asmens duomenų tvarkymo teisinis pagrindas</strong></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Registracija Platformoje, paslaugų užsakymas, teikimas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, vartotojo vardas, el. paštas, tel. Nr., paslaugų teikimo adresas, paslaugų užsakymo bei naudojimo istorija, mokėjimų informacija, kita su paslaugomis susijusi informacija (komunikacija, su paslaugomis susieti pranešimai, papildomi asmens pageidavimai, fotonuotraukos ar filmuota medžiaga, kai fiksuojami gedimai, darbų procesas)</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikimo laikotarpiu ir 5 metus nuo paslaugų suteikimo</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Sutikimo pagrindu tvarkomi asmens duomenys saugomi taip kaip nurodyta aukščiau, nebent asmuo atšaukia duotą sutikimą</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenų subjekto sutikimas (BDAR 6 str. 1 d. a) p.);</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Tvarkyti duomenis būtina siekiant sudaryti ir vykdyti sutartį (BDAR 6 str. 1 dalies b) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) p. )</p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų kokybės užtikrinimas, komunikacija ir konsultacijos dėl paslaugų</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, el. paštas, tel. Nr., paslaugos teikimo adresas, soc. tinklų paskyros viešai matoma informacija (jei komunikuojama per Socialines paskyras), tel. numeris, el. paštas, adresas, žinutės turinys, atsakymo turinys</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Komunikacijos laikotarpiu ir 1 metus po jo pabaigos, o soc. tinklų paskyrose - pagal jų nustatymus, bet ne ilgiau kaip 1 metus nuo komunikacijos pabaigos</p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Sutikimo pagrindu tvarkomi duomenys saugomi kaip nurodyta aukščiau, nebent asmuo atšaukia duotą sutikimą</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenų subjekto sutikimas tokiam duomenų tvarkymui (BDAR 6 str. 1 dalies a) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Bendrovės veiklai reikalingų susitarimų sudarymas ir vykdymas, kitas vidaus administravimas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, tel. Nr., el. paštas, pareigos, darbovietė, adresas, ryšys su atstovaujamu asmeniu, ind. veikl. paž. duomenys, kiti bendradarbiavimui reikalingi duomenys</p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikimo / bendradarbiavimo laikotarpiu ir 5 metus nuo paslaugų teikimo / bendradarbiavimo pabaigos, nebent vadovaujantis LR vyriausiojo archyvaro patvirtinta Bendrųjų dokumentų saugojimo terminų rodykle privalomas ilgesnis saugojimo terminas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Tvarkyti duomenis būtina siekiant sudaryti ir vykdyti sutartį (BDAR 6 str. 1 dalies b) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Finansinių operacijų atlikimas, buhalterinė apskaita, įsiskolinimų valdymas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, asmens kodas (jei reikalinga), el. paštas, tel. numeris, pareigos, adresas, ryšys su atstovaujamu juridiniu asmeniu, atsisk. sąsk. kredito įstaiga, mokėjimų informacija, įmokų surinkimą administruojančios bendrovės perduoti duomenys ir patvirtinimai apie mokėjimus</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenys saugomi pagal finansines operacijas ir buhalterinę apskaitą reglamentuojančius teisės aktus, LR vyriausiojo archyvaro patvirtintą Bendrųjų dokumentų saugojimo terminų rodyklę arba visą sutarties galiojimo / šalių bendradarbiavimo laiką ir 5 metus po sutarties / sutartinių santykių pasibaigimo</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Tvarkyti duomenis būtina siekiant sudaryti ir vykdyti sutartį (BDAR 6 str. 1 dalies b) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Tvarkyti duomenis būtina siekiant įvykdyti duomenų valdytojui nustatytą teisinę prievolę (BDAR 6 str. 1 dalies c) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Kandidatų į siūlomą darbo vietą, įvertinimas ir atranka</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, el. paštas, tel. Nr. adresas, išsilavinimo bei veiklos duomenys, gyvenimo aprašymo turinys, kita kandidato atrankai/ įvertinimui reikalinga ar paties kandidato pateikta informacija</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Atrankos laikotarpį ir 3 mėnesius pasibaigus atrankai, jei yra gautas kandidato sutikimas duomenis saugoti po atrankos</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Kai duomenys gaunami ne konkrečiai atrankai – saugomis 3 mėnesius po jų gavimo dienos</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenų subjekto sutikimas tokiam duomenų tvarkymui (BDAR 6 str. 1 dalies a) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Elektroninių informacijos pateikimo kanalų (Platforma, Socialinės paskyros) valdymas, stebėjimas, funkcionalumo ir saugumo užtikrinimas ir kokybės tobulinimas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>IP adresas, slapukų ir nustatymų pagalba renkami duomenys, naudojama naršyklė, prisijungimo data ir laikas, mobiliojo įrenginio modelis ir gamintojas, mobiliojo įrenginio operacinė sistema (iOS, Android)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Socialinių paskyrų integracijos pagalba renkami duomenys</p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Platformos duomenys saugomi taip, kaip nurodyta šios privatumo politikos skyriuje „Slapukai ir kitos sekimo technologijos“</p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><br /><br /></p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Platformos duomenys, kurie nepatenka į slapukų informaciją, saugomi ne ilgiau kaip 1 metus nuo surinkimo dienos, nebent asmuo atšaukia savo sutikimą (kai duomenys tvarkomi sutikimo pagrindu</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Socialinėse paskyrose informacija saugoma pagal šio tinklo savininko nustatytas sąlygas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenų subjekto sutikimas tokiam duomenų tvarkymui (BDAR 6 str. 1 dalies a) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Naujienų siuntimas, kokybės apklausų vykdymas, tiesioginė rinkodara, paslaugų tobulinimas ir reklama, žaidimų / konkursų organizavimas ir vykdymas</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, el. paštas, tel. numeris, apklausos skelbime / anketoje prašomi nurodyti duomenys, informacija apie naujienlaiškio perskaitymą<span className="Apple-converted-space">&nbsp;</span></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenys saugomi 2 metus nuo duomenų pateikimo dienos, nebent asmuo atšaukia savo duotą sutikimą. Tuomet asmens duomenys yra saugomi iki sutikimo galiojimo pabaigos</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Duomenų subjekto sutikimas tokiam duomenų tvarkymui (BDAR 6 str. 1 dalies a) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Ginčų ir pretenzijų sprendimas<span className="Apple-converted-space">&nbsp;</span></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Vardas, pavardė, el. paštas, tel. numeris, atstovaujamas juridinis asmuo, ryšys su atstovaujamu asmeniu, adresas, pretenzijos ar kito panašaus dokumento turinys, su ginču / pretenzija susijusi informacija / dokumentai, mokamų paslaugų apmokėjimo informacija</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Ginčo nagrinėjimo neteismine tvarka laikotarpiu ir 3 metus po tokio nagrinėjimo pabaigos.</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisminių ginčų dokumentai saugomi ginčo nagrinėjimo laikotarpiui ir 10 metų po galutinio sprendimo įsiteisėjimo</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Tvarkyti duomenis būtina siekiant įvykdyti duomenų valdytojui nustatytą teisinę prievolę (BDAR 6 str. 1 dalies c) punktas)</p>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėti duomenų valdytojo ar trečiosios šalies interesai (BDAR 6 str. 1 d. f) punktas)</p>
													</td>
												</tr>
											</tbody>
										</table>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}><a name="_heading=h.1fob9te" /><br /><br /></p>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}><u>Asmuo turi teisę nesutikti ar bet kuriuo metu atšaukti savo sutikimą tvarkyti jo Asmens duomenis, kai šie tvarkomi jo sutikimo pagrindu.<span className="Apple-converted-space">&nbsp;</span></u></p>

									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Socialinėse paskyrose talpiname informaciją apie savo veiklą, taip pat jose galime organizuoti apklausas, dalintis skelbimais. Socialinėse paskyrose taikoma ir socialinių tinklų, kuriuose yra Socialinės paskyros, valdytojų privatumo sąlygos. Kai susisiekiate su mumis Socialinėse paskyrose ir teikiate tam tikrą informaciją (pavyzdžiui, rašote mums žinutes komentarus po mūsų įrašais), mes, priklausomai nuo Jūsų pasirinktų privatumo nustatymų, galime matyti viešą Jūsų profilio informaciją (vardas, pavardė, atvaizdas, el. paštas ar pan.). Taip pat ši informacija (pavyzdžiui, Jūsų pateiktas komentaras) bus matoma kitiems konkrečios Socialinės paskyros lankytojams.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kai kuriais atvejais mes Jūsų pateiktais kontaktais galime siųsti su mūsų paslaugų užsakymu ar suteikimu susijusias žinutes arba Jums paskambinti, pavyzdžiui, siekdami informuoti apie paslaugų užsakymo patvirtinimą, paslaugų teikimo laiką ir datą, paslaugų teikimo pokyčius ir pan. Tokie pranešimai yra būtini tinkamam mūsų paslaugų teikimui ir jie nėra laikomi reklaminiais pranešimais.</p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>KAIP NAUDOJAME JŪSŲ ASMENS DUOMENIS IR KOKIŲ PRINCIPŲ LAIKOMĖS?</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes gerbiame Jūsų privatumą ir renkame ir tvarkome tik tokius Asmens duomenis, kurių reikia mūsų nurodytiems Asmens duomenų tvarkymo tikslams pasiekti.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Tvarkydami Jūsų Asmens duomenis mes:</p>
										<ol>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Laikomės galiojančių ir taikomų teisės aktų, tame tarpe ir BDAR, reikalavimų;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Tvarkome Jūsų Asmens duomenis teisėtu, sąžiningu ir skaidriu būdu;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Renkame Jūsų Asmens duomenis nustatytais, aiškiai apibrėžtais bei teisėtais tikslais ir netvarkome su tais tikslais nesuderinamu būdu, išskyrus tiek, kiek tai leidžia teisės aktai;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Imamės visų pagrįstų priemonių užtikrinti, kad Asmens duomenys, kurie nėra tikslūs ar išsamūs, atsižvelgiant į jų tvarkymo tikslus, būtų nedelsiant ištaisomi, papildomi, sustabdomas jų tvarkymas arba sunaikinami;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Laikome juos tokia forma, kad Jūsų tapatybę būtų galima nustatyti ne ilgiau, nei tai yra būtina tais tikslais, kuriais Asmens duomenys yra tvarkomi;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Neteikiame Asmens duomenų tretiesiems asmenims ir nepaviešinsime jų kitaip, nei nurodyta Privatumo politikoje ar taikytinuose teisės aktuose;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Užtikriname, kad Jūsų Asmens duomenys būtų tvarkomi saugiai, užtikriname technines ir organizacines saugumo priemones, taip pat prieigą prie Asmens duomenų suteikiame tik tiems savo darbuotojams, kuriems tokia prieiga yra reikalinga dėl jų atliekamų darbo funkcijų.<span className="Apple-converted-space">&nbsp;</span></p>
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>KAM IR KADA PERDUODAME JŪSŲ ASMENS DUOMENIS?</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes perduosime Jūsų Asmens duomenis tik taip, kaip nurodyta šioje Privatumo politikoje.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jūsų Asmens duomenis mes galime perduoti:</p>
										<ol>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mūsų partneriams ar konsultantams (auditoriams, teisininkams ir pan.), kitoms institucijoms, bendrovėms, asmenims, organizacijoms su kuriomis mes dirbame ar bendradarbiaujame teikdami paslaugas ar vykdydami savo veiklą.</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mūsų pasitelktiems Asmens duomenų tvarkytojams, tokiems kaip:</p>
												<ol>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_Hlk99980624" /><span className="Apple-converted-space">&nbsp;</span>Buhalterinės apskaitos ir užsakymų valdymo programos teikėjams - UAB Rivilė;</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>IT, debesų prieglobos, Platformos valdymo ir paslaugų teikėjams – IĮ Guvus;</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Telekomunikacijų<span className="Apple-converted-space">&nbsp;</span><span className="Apple-converted-space">&nbsp;</span>paslaugų teikėjams – UAB Tele2;</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Reklamos, marketingo, naujienų siuntimo (Lietuva) - @MailerLite;</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mokėjimų administravimo paslaugų teikėjams UAB EPS LT, UAB Nets, AB Šiaulių bankas, AB SEB;</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kurjeriams, draudikams, detalių ir medžiagų tiekėjams – UAB Bolt LT, UAB Onninen, UAB Jaukurai, UAB Dahlgera, UAB EOS, UAB BVT ir Partneriai, UAB Kesko Senukai.</p>
													</li>
												</ol>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Kad galėtume skelbti turinį Socialinių tinklų paskyrose, teikiame duomenis šiems socialinių tinklų platformų operatoriams:</p>
												<ol>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Meta Platforms Ireland Ltd. (Airija) ir Meta Platforms, Inc., (JAV), (duomenys saugiai perduodami paslaugų teikėjui pasirašant Europos Komisijos patvirtintas ES standartines sutarčių sąlygas duomenų perdavimui už Europos ekonominės erdvės ribų)</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>LinkedIn Ireland Unlimited Company (Airija) ir LinkedIn Corporation (JAV) (duomenys saugiai perduodami paslaugų teikėjui pasirašant Europos Komisijos patvirtintas ES standartines sutarčių sąlygas duomenų perdavimui už Europos ekonominės erdvės ribų);</p>
													</li>
													<li>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>YouTube Inc. (JAV) (duomenys saugiai perduodami paslaugų teikėjui pasirašant Europos Komisijas patvirtintas ES standartines sutarčių sąlygas duomenų perdavimui už Europos ekonominės erdvės ribų).</p>
													</li>
												</ol>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Teisėsaugos ir ikiteisminio tyrimo institucijoms, teismams ir kitoms ginčus nagrinėjančioms institucijoms, kitiems asmenims, vykdantiems įstatymų pavestas funkcijas, pagal Lietuvos Respublikos teisės aktų numatytą tvarką. Šiems subjektams teikiame įstatymų nustatytą ar pačių subjektų nurodytą privalomą teikti informaciją;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jeigu prireiktų – bendrovėms, kurios ketintų pirkti arba pirktų Bendrovės verslą ar vykdytų su mumis jungtinę veiklą ar bendradarbiautų kita forma, taip pat mūsų įsteigtoms bendrovėms.</p>
											</li>
										</ol>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Paprastai Asmens duomenis mes tvarkome ES / EEE, tačiau kai kuriais atvejais Jūsų Asmens duomenys gali būti perduodami ir už ES / EEE. Jūsų Asmens duomenys už ES / EEE ribų yra perduodami tik esat šioms sąlygoms:</p>
										<ol>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Duomenys perduodami tik mūsų patikimiems partneriams, užtikrinantiems mūsų paslaugų Jums teikimą;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Su tokiais partneriais yra pasirašytos duomenų tvarkymo ar teikimo sutartys, kuriomis jie užtikrina Jūsų Asmens duomenų saugumą;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Europos Sąjungos Komisija yra priėmusi sprendimą dėl valstybės, kurioje yra įsisteigęs mūsų partneris, tinkamumo, t. y. yra užtikrinamas tinkamo lygio saugumas; arba</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Buvo gautas specialus Lietuvos Respublikos Valstybinės duomenų apsaugos inspekcijos leidimas vykdyti tokį perdavimą;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jūs davėte sutikimą dėl Jūsų Asmens duomenų perdavimo už ES / EEE ribų.</p>
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>KOKIAS TEISES JŪS TURITE?<span className="Apple-converted-space">&nbsp;</span></strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jūs, kaip duomenų subjektas, turite tokias su Jūsų Asmens duomenimis susijusias teises:</p>
										<ol>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Žinoti (būti informuotas) apie savo Asmens duomenų tvarkymą (teisė žinoti);</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Susipažinti su savo Asmens duomenimis ir kaip jie yra tvarkomi (teisė susipažinti);</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Reikalauti ištaisyti arba, atsižvelgiant į Asmens duomenų tvarkymo tikslus, papildyti neišsamius Jūsų Asmens duomenis (teisė ištaisyti);</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Reikalauti sunaikinti Jūsų Asmens duomenis arba sustabdyti Jūsų Asmens duomenų tvarkymo veiksmus (išskyrus saugojimą) (teisė „būti pamirštam“);</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Reikalauti, kad mes apribotume Asmens duomenų tvarkymą esant vienai iš teisėtų priežasčių (teisė apriboti);</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Teisę į duomenų perkėlimą (teisė perkelti). Ši teisė bus įgyvendinama tik tuo atveju, jei egzistuoja jos įgyvendinimo pagrindai ir tinkamos techninės priemonės, užtikrinančios, kad perkeliant prašomus Asmens duomenis kitų asmenų duomenims nebus sukeltas saugumo pažeidimo pavojus;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Teisė nesutikti kad būtų tvarkomi Asmens duomenys. Ši teisė gali būti įgyvendinama, kai Asmens duomenis tvarkome teisėto mūsų ar trečiosios šalies intereso pagrindu, įskaitant profiliavimą. Jums paprieštaravus, mes Jūsų Asmens duomenis toliau galėsime tvarkyti tik dėl įtikinamų teisėtų priežasčių, kurios yra viršesnės už Jūsų interesus, teises ir laisves, arba siekiant pareikšti, vykdyti ar apginti teisinius reikalavimus;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Atšaukti savo sutikimą tvarkyti Asmens duomenis bet kuriuo metu, jeigu duomenys tvarkomi sutikimo pagrindu. Kai šie duomenys tvarkomi tiesioginės rinkodaros tikslais, įskaitant profiliavimą, kiek jis susijęs su tiesiogine rinkodara;</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Teisė pateikti skundą Valstybinei duomenų apsaugos inspekcijai.<span className="Apple-converted-space">&nbsp;</span></p>
											</li>
										</ol>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei nenorite, kad Jūsų Asmens duomenys būtų tvarkomi tiesioginės rinkodaros tikslu, įskaitant profiliavimą, galite atsisakyti tokio duomenų tvarkymo, nenurodydami atsisakymo (nesutikimo) motyvų, parašę el. laišką adresu info@ukvedziai.lt, ar kitu Jums pateiktoje žinutėje nurodytu būdu (pavyzdžiui, paspaudę atitinkamą nuorodą naujienlaiškyje).<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes galime atsisakyti įgyvendinti aukščiau išvardintas Jūsų teises, išskyrus nesutikimą tvarkyti Jūsų Asmens duomenis tiesioginės rinkodaros tikslu ar kitais atvejais, kai Asmens duomenys tvarkomi Jūsų sutikimu, kai Jūsų prašymo mums leidžia netenkinti BDAR nuostatos, ar, kai, įstatymų numatytais atvejais, reikia užtikrinti nusikaltimų, tarnybinės ar profesinės etikos pažeidimų prevenciją, tyrimą ir nustatymą, taip pat duomenų subjekto, mūsų ir kitų asmenų teisių ir laisvių apsaugą.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Bet kokį prašymą ar nurodymą, susijusį su Asmens duomenų tvarkymu, Jūs galite pateikti mums raštu el. paštu info@ukvedziai.lt. Teikiant tokį prašymą, mes, tam kad geriau suprastume Jūsų prašymo turinį, galime paprašyti užpildyti reikiamas formas, taip pat pateikti asmens dokumentą ar kitą informaciją (pavyzdžiui, patvirtinti savo tapatybę elektroniniu parašu), kuri mums padės įsitikinti Jūsų tapatybe. Pateikus prašymą el. paštu, atsižvelgiant į jo turinį, mes galime paprašyti atvykti pas mus ar pateikti prašymą raštu.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes, gavę Jūsų prašymą ar nurodymą, dėl Asmens duomenų tvarkymo, ne vėliau kaip per 1 mėnesį nuo kreipimosi dienos, pateiksime atsakymą bei atliksime prašyme nurodytus veiksmus arba informuosime, kodėl atsisakome juos atlikti. Prireikus, nurodytas laikotarpis gali būti pratęstas dar 2 mėnesiais, atsižvelgiant į prašymų sudėtingumą ir skaičių. Tokiu atveju per 1 mėnesį nuo prašymo gavimo dienos mes informuosime Jus apie tokį pratęsimą.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei Jums paprašius Asmens duomenys yra ištrinami, mes saugosime tik informacijos kopijas, kurios yra būtinos siekiant apsaugoti mūsų ir kitų asmenų teisėtus interesus, laikytis valstybės institucijų įpareigojimų, spręsti ginčus, atpažinti trikdžius arba laikytis bet kokių susitarimų, kuriuos esate sudarę su mumis.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_heading=h.gjdgxs" /><span className="Apple-converted-space">&nbsp;</span><strong>AR MES SIŲSIME JUMS NAUJIENAS?<span className="Apple-converted-space">&nbsp;</span></strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_heading=h.3znysh7" /><span className="Apple-converted-space">&nbsp;</span>Jūs galite duoti mums sutikimą, jei pageidaujate gauti mūsų pasiūlymus ir informaciją apie mūsų paslaugas bei veiklą. Jūs taip pat galite duoti mums sutikimą padėti mums įvertinti mūsų paslaugų kokybę.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jums sutikus, Jūsų nurodytu elektroniniu paštu arba žinutėmis į Jūsų nurodytą telefono numerį, Jums bus siunčiamos Jūsų pasirinktos naujienos ir (ar) mes teirausimės apie mūsų bei mūsų teikiamų paslaugų ir aptarnavimo kokybę.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Išsiuntę naujienas mes galime rinkti informaciją apie juos gavusius asmenis, pavyzdžiui, kurį pranešimą asmenys atidarė, kokias nuorodas spaudė ir pan. Tokia informacija renkama siekiant pasiūlyti Jums aktualias ir labiau pritaikytas naujienas.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jūsų kontaktai gali būti perduoti mūsų partneriams / tvarkytojams, kurie mums teikia naujienų siuntimo ar kokybės vertinimo paslaugas.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Net jei davėte sutikimą tvarkyti Asmens duomenis tiesioginės rinkodaros tikslais, galite bet kada lengvai atšaukti šį sutikimą dėl visos ar dalies Asmens duomenų tvarkymo veiklos. Norėdami tai padaryti, galite:</p>
										<ol>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>pranešti mums apie savo atsisakymą tokiu būdu, kuris nurodytas elektroniniuose pranešimuose ir / ar pasiūlymuose (pavyzdžiui, spustelėjus naujienlaiškio nuorodą „atsisakyti prenumeratos“ ir pan.); arba</p>
											</li>
											<li>
												<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>atsiųsti mums pranešimą šioje Privatumo politikoje nurodytu elektroninio pašto adresu. Jei tokiu būdu prašote atšaukti sutikimą, galime paprašyti patvirtinti Jūsų tapatybę.</p>
											</li>
										</ol>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jums atšaukus sutikimą, mes stengsimės nedelsiant nutraukti naujienų siuntimą Jūsų kontaktais.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Sutikimo atšaukimas automatiškai neįpareigoja mus sunaikinti Jūsų Asmens duomenų ar pateikti informaciją Jums apie mūsų tvarkomus Asmens duomenis, todėl dėl šių veiksmų Jums reikėtų pateikti atskirą prašymą.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei mes organizuojame žaidimus, akcijas ar konkursus, dalyvavimas juose yra neprivalomas. Norėdami dalyvauti mūsų skelbiame konkurse, žaidime ar akcijoje, Jūs turite pateikti mūsų nurodytą informaciją, tame tarpe ir Asmens duomenis. Žaidimus, akcijas ar konkursus vykdysime ir Asmens duomenis tvarkysime pagal konkrečiam žaidimui, akcijai ar konkursui nustatytas taisykles. Jūs turite teisę bet kada atsisakyti dalyvauti mūsų žaidimuose, konkursuose, akcijose bei atšaukti Jūsų duotą sutikimą dėl Asmens duomenų tvarkymo ir naudojimo šiuo tikslu. Atšaukus sutikimą, Jūs būsite išbraukiamas iš žaidimo, akcijos ar konkurso dalyvių sąrašo ir žaidime, akcijoje ar konkurse nebedalyvausite.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jums dalyvaujant mūsų žaidimuose, konkursuose ir akcijose, mes turime teisę susisiekti su Jumis Jūsų pateiktais kontaktiniais duomenimis ir / ar paskelbti informaciją apie Jus, kaip laimėtoją, Platformoje ar kituose informacijos skelbimo kanaluose, kuriuos mes nurodome žaidimo, konkurso ar akcijos taisyklėse. Mes, siekdami tvarkyti žaidimo, akcijos ar konkurso registraciją ir / ar nustatyti laimėtojus, galime naudoti programą, kuri pagrįsta automatiniu sprendimų priėmimu. Apie tai bus pranešta žaidimo, akcijos, konkurso taisyklėse arba bus akivaizdu iš mūsų pateikiamų nuorodų. Tokiu atveju, jei Jūs nepageidaujate, kad Jūsų atžvilgiu būtų taikomas automatinis sprendimų priėmimas, dalyvauti žaidime, akcijoje ar konkurse negalėsite.</p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_heading=h.2et92p0" /><span className="Apple-converted-space">&nbsp;</span><strong>KAIP MES SAUGOME JŪSŲ ASMENS DUOMENIS?</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Jūsų Asmens duomenys tvarkomi atsakingai, saugiai ir yra saugomi nuo praradimo, neleistino naudojimo ir pakeitimų. Įdiegėme fizines ir technines priemones (tokias kaip valdomos prieigos, dalinimosi duomenimis apribojimai, šifravimas, sistemų atnaujinimas, slaptažodžių naudojimas, tapatybės tikrinimas, apsauga nuo virusų ar kenkėjiškų priemonių, duomenų apsaugai skirta dokumentacija ir pan.), kad apsaugotume informaciją, kurią renkame nuo atsitiktinio ar neteisėto sunaikinimo, sugadinimo, pakeitimo, praradimo, atskleidimo, taip pat nuo bet kokio kito neteisėto tvarkymo. Asmens duomenų saugumo priemonės nustatomos atsižvelgiant į rizikas, kurios kyla tvarkant Asmens duomenis.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}><a name="_heading=h.35nkun2" /><span className="Apple-converted-space">&nbsp;</span>Mūsų darbuotojai yra raštiškai įsipareigoję neatskleisti ir neplatinti Jūsų Asmens duomenų tretiesiems, neautorizuotiems asmenims.</p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_heading=h.1ksv4uv" /><span className="Apple-converted-space">&nbsp;</span><strong>KAIP MES NAUDOJAME SLAPUKUS IR KITAS SEKIMO TECHNOLOGIJAS?</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Slapukai – tai nedidelis tekstinis failas, saugantis informaciją (dažnai sudarytą tik iš skaičių ir raidžių sekos, identifikuojančios įrenginį, tačiau gali turėti ir kitos informacijos), kuri naudojama įrenginio (kompiuterio, planšetinio kompiuterio, mobiliojo telefono ar kt.) naršyklėje (pvz., „Google Chrome“, „Microdoft Edge“, „Firefox“, „Mozilla“, „Opera“ ir kt.) pagal jos nustatymus ir išsaugoma įrenginio kietajame diske. Privatumo politikoje terminą „slapukai“ naudojame slapukams ir kitoms panašioms technologijoms, pavyzdžiui, pikselių žymoms (angl. „Pixel Tags“), žiniatinklio indikatoriams (angl. „Web Beacon“), tinklo duomenų rinkėjams (angl. „clear GIF“) apibūdinti.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes naudojame slapukus informacijos srautų ir vartotojų elgesio analizei, pasitikėjimui skatinti bei saugumui užtikrinti, taip pat siekdami užtikrinti Platformos tinkamą veikimą, jos tobulinimą, Jūsų pasirinktų nustatymų įsiminimą, personalizuoti Jums rodomą turinį, susieti Platformą su Socialinėmis paskyromis ar mūsų mobiliąja aplikacija.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Jūs galite pasirinkti, ar norite priimti slapukus. Jeigu nesutinkate, kad į Jūsų kompiuterio ar į kito įrenginio naršyklę būtų įrašomi slapukai, galite pažymėti apie tai sutikimo dėl slapukų juostoje, pakeisti naudojamos naršyklės nustatymus ir išjungti slapukus (visus iš karto ar po vieną, ar grupėmis). Jei norite slapukų atsisakyti savo mobiliajame įrenginyje, Jūs turite sekti šiam įrenginiui skirtas oficialias instrukcijas. Atkreipiame dėmesį, kad kai kuriais atvejais slapukų atsisakymas gali sulėtinti naršymo spartą, apriboti tam tikrų interneto svetainių funkcijų veikimą arba blokuoti prieigą prie svetainės. Išsamesnė informacija pateikta adresu<span className="Apple-converted-space">&nbsp;</span><a href="http://www.allaboutcookies.org/" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><u>http://www.AllAboutCookies.org</u></a><span className="Apple-converted-space">&nbsp;</span>arba<span className="Apple-converted-space">&nbsp;</span><a href="https://www.google.com/privacy_ads.html" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><u>https://www.google.com/privacy_ads.html</u></a>.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Trečiųjų šalių slapukų naudojimą su reklama susijusiais tikslais galite išjungti apsilankę „Network Advertising“ išjungimo puslapyje adresu<span className="Apple-converted-space">&nbsp;</span><a href="http://www.networkadvertising.org/managing/opt_out.asp" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><u>http://www.networkadvertising.org/managing/opt_out.asp</u></a>.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Mes galime naudoti privalomus slapukus, kurie būtini Platformos veikimui užtikrinti, analitinius slapukus, funkcinius slapukus, kurie skirti analizuoti Platformos lankymą, įsiminti vartotojų pageidavimus ir pritaikyti juos Platformai, kad mes galėtume suteikti patobulintas funkcijas, našumo slapukus, trečiųjų asmenų slapukus, kuriuos naudoja trečiosios šalys, reklamos slapukus, kurie skirti rodyti Jums personalizuotą ir bendrą reklamą.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Siekdami rodyti jums aktualesnę personalizuotą reklamą, mes ir mūsų reklamos partneriai naudoja tikslinius slapukus. Personalizuota reklama jums bus rodoma jūsų sutikimu. Reklamos personalizavimo slapukai naudojami siekiant išmatuoti grupę, aktyvuoti kontekstinę reklamą ir / ar tikslines kampanijas.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Kai kurie tretieji asmenys, pavyzdžiui, socialinių tinklų valdytojai, gali naudoti jų pačių sukurtus anoniminius slapukus, skirtus tam, kad jų sukurtos programos ar aplikacijos būtų pritaikytos prie jūsų poreikių.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Mes nekontroliuojame trečiųjų šalių slapukų ir stebėjimo technologijų naudojimo, tad jei norite sužinoti detalesnę informaciją, mes rekomenduojame patikrinti atitinkamos trečiosios šalies privatumo politiką.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Platformoje naudojamų slapukų sąrašas:<span className="Apple-converted-space">&nbsp;</span></p>
										<table cellPadding={10} cellSpacing={0} width={806}>
											<tbody>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><strong>Slapuko pavadinimas</strong></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={169}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}><strong>Aprašymas/</strong></p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><strong>Naudojimo tikslas</strong></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={131}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><strong>Sukūrimo momentas</strong></p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={81}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}><strong>Galiojimo laikas</strong></p>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>&nbsp;</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={118}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><strong>Naudojami duomenys</strong></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={205}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>terms</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={169}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Naudojamas sutikimui su privatumo politika</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={131}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Jums užėjus į Platformą</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={81}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>7<span className="Apple-converted-space">&nbsp;</span>d.</p>
													</td>
													<td style={{ border: '0.75pt solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={118}>
														<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Unikalus ID / Užkoduota eilutė</p>
													</td>
												</tr>
											</tbody>
										</table>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent' }}>Mes taip pat naudojame šiuos produktus bei įrankius, kurie įrašo slapukus:</p>
										<table cellPadding={10} cellSpacing={0} width={805}>
											<tbody>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Google Analytics</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Analizuoja Platformos naudojimą, sudaro ataskaitas, padeda planuoti bei prognozuoti Platformos veiklą. Surinkti duomenys perduodami ir saugomi Google serveryje JAV. Jūs galite neleisti Google Analytics analizuoti informacijos, pakeisdami naršyklės nustatymus. Tokiu atveju Jums bus įrašytas atsisakymo slapukas. Tačiau jei ištrinsite visus slapukus, atsisakymo slapukas taip pat gali būti ištrintas. Taip pat galite neleisti Google fiksuoti duomenų, įdiegdami naršyklės papildinį iš<span className="Apple-converted-space">&nbsp;</span></span><a href="https://tools.google.com/dlpage/gaoptout?hl=en" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://tools.google.com/dlpage/gaoptout?hl=en</u></span></a><span lang="lt-LT">.</span></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Google Adsense</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Įvertina Platformos naudojimą rodomų reklamų atžvilgiu ir teikia ataskaitas. Surinkti duomenys perduodami ir saugomi Google serveryje JAV. Jei nepageidaujate gauti specialiai pritaikytos reklamos, Jūs galite adresu<span className="Apple-converted-space">&nbsp;</span></span><a href="https://www.google.com/settings/ads" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://www.google.com/settings/ads</u></span></a><span lang="lt-LT"><span className="Apple-converted-space">&nbsp;</span>pagal poreikį pakeisti nustatymus. Tokiu atveju Jums bus įrašytas atsisakymo slapukas. Tačiau jei ištrinsite visus slapukus, atsisakymo slapukas taip pat gali būti ištrintas.</span></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Google Remarketing</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Leidžia vartotojams, pakartotinai gauti reklamas Google partnerių tinklo svetainėse. Jei nepageidaujate gauti specialiai pritaikytos reklamos ir/ ar norite atsisakyti Google slapukų, galite išjungti Google reklamų nustatymuose adresu<span className="Apple-converted-space">&nbsp;</span></span><a href="https://www.google.com/settings/ads" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://www.google.com/settings/ads</u></span></a><span lang="lt-LT"><span className="Apple-converted-space">&nbsp;</span>pagal poreikį pakeisdami nustatymus. Tokiu atveju Jums gali būti įrašytas atsisakymo slapukas. Tačiau jei ištrinsite visus slapukus, atsisakymo slapukas taip pat gali būti ištrintas</span></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="left" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Google Tag Manager</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Atlieka Platformos naudojimo analizę. Surinkti duomenys apie tai, kaip Jūs naudojate Platformą (kartu su IP adresu), perduodama ir kaupiama į Google serveryje JAV. Ši informacija naudojama analizuoti naudojimąsi Platforma, rengti ataskaitas apie Platformos populiarumą, teikti kitas su Platforma ir jos naudojimu susijusias paslaugas. Google gali persiųsti šią informaciją tretiesiems asmenims, jei to reikalauja taikytini teisės aktai arba jei šie tretieji asmenys tvarko informaciją Google vardu. Google nesies Jūsų IP adreso su jokiais kitais Google turimais duomenimis. Daugiau informacijos:<span className="Apple-converted-space">&nbsp;</span></span><a href="https://support.google.com/tagmanager/answer/9323295?hl=en" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://support.google.com/tagmanager/answer/9323295?hl=en</u></span></a><span lang="lt-LT">.</span></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Facebook Pixel</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Naudojamas pakartotinės rinkodaros tikslais, kad galėtume su jumis vėl susisiekti. Tai leidžia mums rodyti interesais pagrįstą reklamą (Facebook Ads), kai Jūs lankotės socialiniame tinkle Facebook arba kitose svetainėse, taip pat naudojančiose šį įrankį. Tokiu būdu stengiamės rodyti jus dominančią reklamą. Daugiau informacijos kaip veikia Facebook Pixel<span className="Apple-converted-space">&nbsp;</span></span><a href="https://www.facebook.com/business/learn/facebook-ads-pixel" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://www.facebook.com/business/learn/facebook-ads-pixel</u></span></a><span lang="lt-LT">.</span></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>LinkedIn Insight tag</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Padeda įvertinti mūsų LinkedIn puslapio lankomumą, jo reklamų efektyvumą ir šių duomenų pagrindu rodyti pranešimus/ reklamines žinutes. Per LinkedIn puslapį prieinama nuasmeninta informacija: puslapio lankomumas, mūsų pranešimus sekančių asmenų kiekis; asmenų, kuriuos pasiekia puslapio/ reklamos turinys skaičius ir jų veiksmai (dalinasi, paspaudžia „patinka“, kt.); naujų sekėjų skaičius, pasiskirstymas pagal lytį, miestą, valstybę, naršyklės kalbą. Daugiau informacijos<span className="Apple-converted-space">&nbsp;</span></span><a href="https://www.linkedin.com/legal/cookie-policy" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://www.linkedin.com/legal/cookie-policy</u></span></a><span lang="lt-LT">.</span></p>
													</td>
												</tr>
												<tr valign="top">
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={108}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}>Hotjar</p>
													</td>
													<td style={{ border: '1px solid rgb(0, 0, 0)', padding: '0in 0.08in' }} width={655}>
														<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.1in', direction: 'ltr', backgroundColor: 'transparent' }}><span lang="lt-LT">Hotjar yra analitinė ir grįžtamojo ryšio priemonė, leidžianti mums geriau suprasti, kaip naudojama Interneto svetainė ir tobulinti jos naudojimo galimybes. Hotjar įrašo slapukus tam, kad padėtų mums sekti lankytojų veiksmus Interneto svetainėje ir šie slapukai nekaupia jokios asmenį identifikuojančios informacijos. Daugiau informacijos<span className="Apple-converted-space">&nbsp;</span></span><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><span lang="lt-LT"><u>https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information</u></span></a><span lang="lt-LT">.</span></p>
													</td>
												</tr>
											</tbody>
										</table>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><a name="_heading=h.tyjcwt" /><span className="Apple-converted-space">&nbsp;</span><strong>SUSISIEKITE SU MUMIS</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei dėl šioje Privatumo politikoje pateiktos informacijos Jums iškiltų klausimų, prašome kreiptis: el. paštu<span className="Apple-converted-space">&nbsp;</span>info@ukvedziai.lt,<span className="Apple-converted-space">&nbsp;</span>Telefonu: 8 670 00858,<span className="Apple-converted-space">&nbsp;</span>adresu: Panerių g. 64, Vilnius;<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei norite pateikti skundą dėl mūsų atlikto Asmens duomenų tvarkymo, pateikite jį mums raštu, nurodydami kuo daugiau informacijos. Mes bendradarbiausime su Jumis ir stengsimės nedelsiant išspręsti visus klausimus.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei manote, kad vadovaujantis BDAR, Jūsų teisės buvo pažeistos, galite pateikti skundą mūsų priežiūros institucijai – Valstybinei duomenų apsaugos inspekcijai, daugiau informacijos ir kontaktinius duomenis rasite inspekcijos svetainėje (<a href="https://vdai.lrv.lt/" style={{ color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}><u>https://vdai.lrv.lt/</u></a>). Siekiame operatyviai ir taikiai išspręsti visus ginčus, tad visų pirma kviečiame kreiptis į mus.<span className="Apple-converted-space">&nbsp;</span></p>
									</li>
								</ol>
							</li>
							<li>
								<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}><strong>BAIGIAMOSIOS NUOSTATOS</strong></p>
								<ol>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Mes galime keisti šią Privatumo politiką. Apie pakeitimus pranešime Platformoje patalpindami atnaujintą Privatumo politiką, Jūsų el. paštu, jei tokį būsite mums pateikęs, ar kitais įprastais komunikacijos būdais. Privatumo politikos pakeitimai įsigalioja nuo atnaujinimo datos, nurodytos Privatumo politikoje, nebent būtų nurodytas kitas įsigaliojimo terminas.</p>
									</li>
									<li>
										<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in', border: 'none', padding: '0in' }}>Jei Jūs po pranešimo apie Privatumo politikos sąlygų pakeitimą ir toliau naudojatės Platforma, užsakote paslaugas iš mūsų, talpinate skelbimus naudojatės mūsų Socialinėmis paskyromis, susisiekiate su mumis, laikoma, kad Jūs sutikote su pakeistomis Privatumo politikos sąlygomis.</p>
									</li>
								</ol>
							</li>
						</ol>
						<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}><br /><br /></p>
						<p style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}>Paskutinė Privatumo politikos atnaujinimo data:<span className="Apple-converted-space">&nbsp;</span>2023-02-01</p>
						<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'left', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in', border: 'none', padding: '0in' }}><br /><br /></p>
					</div>
				</div>
				<div className="pt">
					<h3 className={pp1 ? 'opener open' : 'opener'} onClick={() => { setpp1(!pp1) }}>{t('UAB „ŪKVEDŽIAI“ PASLAUGŲ TEIKIMO TAISYKLĖS')} </h3>
					<div className={pp1 ? ' pt ' : ' pt hidden '}>
						<div>
							<p align="center" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}></p>
							<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}>Šios paslaugų teikimo taisyklės nustato bendrąsias UAB „Ūkvedžiai“ teikiamų namų priežiūros paslaugų teikimo sąlygas bei šalių tarpusavio teises ir pareigas, kai asmuo renkasi ir užsako paslaugas.</p>
							<ol style={{ caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 'auto', textAlign: 'start', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', widows: 'auto', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none' }}>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>SĄVOKOS IR APIBRĖŽIMAI</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Taisyklės<span className="Apple-converted-space">&nbsp;</span></strong>–<strong><span className="Apple-converted-space">&nbsp;</span></strong>šios UAB „Ūkvedžiai“ paslaugų teikimo taisyklės.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Paslaugų teikėjas<span className="Apple-converted-space">&nbsp;</span></strong>– UAB „Ūkvedžiai“<span className="Apple-converted-space">&nbsp;</span>juridinio asmens kodas 305588699, adresas Panerių g. 64, LT-03160 Vilnius, Lietuvos Respublika.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Klientas</strong><span className="Apple-converted-space">&nbsp;</span>– asmuo, kuris užsako ir naudojasi Paslaugų teikėjo paslaugomis.<span className="Apple-converted-space">&nbsp;</span><u>Klientas – vartotojas</u><span className="Apple-converted-space">&nbsp;</span>yra fizinis asmuo, sulaukęs 18 metų, užsakantis paslaugas asmeniniams, šeimos ar ūkio poreikiams tenkinti, su verslu ar profesija nesusijusiais tikslais.<span className="Apple-converted-space">&nbsp;</span><u>Klientas – verslininkas</u><span className="Apple-converted-space">&nbsp;</span>yra fizinis ar juridinis asmuo, užsakantis paslaugas su verslu ar profesija susijusiais tikslais.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Šalys<span className="Apple-converted-space">&nbsp;</span></strong>–<strong><span className="Apple-converted-space">&nbsp;</span></strong>Paslaugų teikėjas ir Klientas.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Svetainė</strong><span className="Apple-converted-space">&nbsp;</span>– Paslaugų teikėjo valdoma ir administruojama interneto svetainė pasiekiama adresu<span className="Apple-converted-space">&nbsp;</span><u><a href="http://www.ukvedziai.lt/" style={{ color: 'rgb(5, 99, 193)', textDecoration: 'underline' }}>www.ukvedziai.lt</a></u><strong>.</strong></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Paslaugos</strong><span className="Apple-converted-space">&nbsp;</span>– Paslaugų teikėjo siūlomos namų priežiūros paslaugos, įskaitant, bet neapsiribojant, konsultacijas dėl namų įrengimo, remonto, taip pat aprūpinimą statybinėmis medžiagomis. Paslaugos apima ir su jomis susijusias prekes (pavyzdžiui, dalis ar medžiagas, reikalingas Paslaugoms teikti).<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Sutartis</strong><span className="Apple-converted-space">&nbsp;</span>– tarp Paslaugų teikėjo ir Kliento pagal Taisykles sudaryta paslaugų teikimo sutartis.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Paslaugų kaina<span className="Apple-converted-space">&nbsp;</span></strong>-<span className="Apple-converted-space">&nbsp;</span>pinigų suma, kurią Klientas turi sumokėti už jam faktiškai suteiktas Paslaugas.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>PVM sąskaita faktūra<span className="Apple-converted-space">&nbsp;</span></strong>-<strong><span className="Apple-converted-space">&nbsp;</span></strong>tai Lietuvos Respublikos pridėtinės vertės mokesčio įstatyme nustatytus privalomus reikalavimus atitinkantis dokumentas, kuriuo įforminamas Paslaugų teikimas, taip pat nurodoma suteiktų Paslaugų apimtis<span className="Apple-converted-space">&nbsp;</span>bei Kliento mokėtina Paslaugų kaina.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>Privatumo politika<span className="Apple-converted-space">&nbsp;</span></strong>– dokumentas, kuriame aprašytos Paslaugų teikėjo taikomos asmens duomenų tvarkymo sąlygos.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
									</ol>
								</li>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>BENDROSIOS NUOSTATOS</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas sudaro Klientams galimybę užsakyti jo siūlomas Paslaugas. Visos Paslaugų teikėjo teikiamos Paslaugos yra mokamos.<span className="Apple-converted-space">&nbsp;</span><strong>Dėmesio</strong>:<span className="Apple-converted-space">&nbsp;</span><u>Paslaugos teikiamos tik Vilniaus apskrityje</u>.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Taisyklės yra teisiškai privalomas dokumentas. Taisyklių privalo laikytis kiekvienas Klientas. Užsakyti Paslaugų teikėjo paslaugas galima tik susipažinus su Taisyklėmis ir įsipareigojus jų laikytis. Kai Klientas nepritaria Taisyklėms (ar daliai jų), jis negali kreiptis į Paslaugų teikėją ir užsakyti jo Paslaugų.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas gali keisti Taisykles, jei tobulinamos ar keičiamos teikiamos Paslaugos, pasikeičia Paslaugų teikėjo verslo praktika (pavyzdžiui, veiklos vykdymo sąlygos, veiklos geografija, kainodara), taip pat Taisyklių pakeitimai būtini dėl pasikeitusių teisės aktų ar valstybės institucijų nurodymų.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Apie Taisyklių pakeitimus Klientai informuojami pranešimu Svetainėje arba Kliento el. paštu, jei jis Paslaugų teikėjui žinomas. Taisyklių pakeitimai įsigalioja po jų paskelbimo Svetainėje ir taikomi tik Paslaugų užsakymams, atliktiems po Taisyklių pakeitimo. Jei Klientas nesutinka su Taisyklių pakeitimais, jis turi neužsakyti Paslaugų. Jei Klientas po Taisyklių pakeitimų paskelbimo teikia Paslaugų užsakymus, laikoma, kad jis sutinka su Taisyklių pakeitimais.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas neatsako ir neprisiima rizikos, jei Klientas iš dalies ar visiškai nesusipažino su Taisyklėmis. Atsižvelgiant į tai, kad Klientui tokia susipažinimo galimybė buvo suteikta, Paslaugų teikėjas laikomas tinkamai įvykdžiusiu savo informavimo pareigas.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas, atsižvelgdamas į Taisyklių pažeidimo mastą, pobūdį, trukmę, pavyzdžiui, kiek laiko Klientas nevykdo įsipareigojimų, ar jie nevykdomi tyčia, ar pažeidimas kartojasi, turi teisę be įspėjimo apriboti Kliento galimybę užsakyti ar gauti Paslaugas. Paslaugų teikėjas neapribos Paslaugų užsakymo ar teikimo dėl mažareikšmių pažeidimų.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Įvykus nenugalimos jėgos (<em>force majeure)<span className="Apple-converted-space">&nbsp;</span></em>aplinkybėms (tokioms kaip, pavyzdžiui, gaisras, valstybės institucijų veiksmai, ekstremalios padėties / karantino paskelbimas, kariniai veiksmai ar pilietiniai neramumai, ataka prieš Paslaugų teikėjo naudojamas elektronines sistemas, įskaitant tas, kurias valdo asmenys, teikiantys paslaugas Paslaugų teikėjui, ir pan.), Paslaugų teikėjas turi teisę sustabdyti sudarytos sutarties įvykdymą iki nenugalimos jėgos aplinkybių išnykimo, apie tai pranešdamas Klientui. Jei Klientui vėlesnis sutarties įvykdymas yra neaktualus, Klientas gali atsisakyti užsakymo apie tai pranešdamas Paslaugų teikėjui. Jei, egzistuojant nenugalimos jėgos aplinkybėms, galima vykdyti patvirtintus užsakymus, visos teisės ir pareigos, susijusios su tokiais užsakymais, išlieka galioti.</p>
										</li>
									</ol>
								</li>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>PASLAUGŲ UŽSAKYMAS IR PASLAUGŲ TEIKIMO SUTARTIES SUDARYMAS</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugos teikiamos pagal Klientų užsakymus. Užsakymo pateikimo būdas gali skirtis priklausomai nuo Paslaugos pobūdžio. Konkretus užsakymo pateikimo būdas Klientui parodomas prie konkrečios Paslaugų grupės ar Paslaugos.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Užsakymų pateikimo tvarka:</p>
											<ol>
												<li>
													<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Svetainėje – užpildant užsakymo formą ir pateikiant reikalingus duomenis. Pildydamas užsakymo formą Klientas nurodo duomenis, kurie pažymėti kaip privalomi, taip pat savo nuožiūra užpildo duomenis, kurie pažymėti kaip neprivalomi. Klientas užsakymą pateikia paspausdamas mygtuką „Išsikviesti“. Atlikus aukščiau nurodytus žingsnius Klientas gaus SMS žinutę, kurioje nurodytu būdu turės patvirtinti užsakymą. Klientas užsakymą turi patvirtinti per 2 (dvi) valandas. Klientui nepatvirtinus užsakymo, užsakymas anuliuojamas ir Paslauga laikoma neužsakyta.</p>
												</li>
												<li>
													<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Telefonu – tokiu atveju Paslaugų teikėjas išsiaiškina Kliento poreikius ir suformuoja užsakymą. Klientas gaus SMS žinutę, kurioje nurodytu būdu turės patvirtinti užsakymą. Klientas užsakymą turi patvirtinti per 2 (dvi) valandas. Klientui nepatvirtinus užsakymo, užsakymas anuliuojamas ir Paslauga laikoma neužsakyta.</p>
												</li>
											</ol>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Esant poreikiui, Paslaugų teikėjas iki užsakymo patvirtinimo atsiuntimo, gali susisiekti su Klientu jo nurodytu telefonu ar el. paštu, kad patikslintų užsakymo sąlygas.<span className="Apple-converted-space">&nbsp;</span><strong>Dėmesio</strong>: kai kuriais atvejais užsakymo nebus galima įvykdyti iš karto (pavyzdžiui, kai nėra aišku, kokių medžiagų, įrankių reikės, koks gedimas ar problema įvyko, neaiškus problemos atsiradimo šaltinis ar pan.). Tokiais atvejais Paslaugų teikėjas apie situaciją nedelsdamas informuos Klientą. Esant tokiai situacijai, Klientas už Paslaugų teikėjo darbuotojo atvykimą pas jį ir esamos situacijos įvertinimą apmoka minimalų iškvietimo mokestį - už 1 (vieną) darbo valandą.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Prieš pateikdamas Paslaugų užsakymą ir paspausdamas mygtuką „Išsikviesti“, Klientas privalo patvirtinti, kad susipažino su Taisyklėmis. Taisyklių naujausia redakcija yra pasiekiama<span className="Apple-converted-space">&nbsp;</span><u><a href="http://www.ukvedziai.lt/" style={{ color: 'rgb(5, 99, 193)', textDecoration: 'underline' }}>www.ukvedziai.lt</a></u>.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Kai Klientas kreipiasi į Paslaugų teikėją, atlieka veiksmus, reikalingus Paslaugoms užsakyti, ir patvirtina susipažinimą su Taisyklėmis, Taisyklės tampa galiojančia bei teisiškai įpareigojančia Sutartimi tarp Paslaugų teikėjo ir Kliento. Sėkmingai atliktas užsakymas patvirtinamas el. laišku ir / ar SMS žinute su užsakymo numeriu ir pagrindine užsakymo informacija.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Sudarydamas Sutartį Klientas įsipareigoja sumokėti Paslaugų kainą ir priimti užsakytas Paslaugas, o Paslaugų teikėjas – suteikti užsakytas Paslaugas Klientui.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Sutartis galioja iki visiško įsipareigojimų įvykdymo. Sutartis laikoma įvykdyta kai Klientas visiškai sumoka už Paslaugas ir Paslaugos suteikiamos bei perduodamos Klientui Taisyklių nustatyta tvarka.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Sutartis sudaroma kiekvienam Kliento užsakymui, o jos<span className="Apple-converted-space">&nbsp;</span>kopija<span className="Apple-converted-space">&nbsp;</span>saugoma Paslaugų teikėjo, jo pasirinktu būdu.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Pateikus užsakymą Klientas negalės jo papildyti. Norėdamas užsakyti papildomų Paslaugų, Klientas turi pateikti naują užsakymą.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Patvirtinus užsakymą, Paslaugų teikėjas dės maksimalias pastangas jį įvykdyti ir užsakymas gali būti neįvykdytas ar įvykdytas iš dalies tik išimtinais atvejais. Susidarius tokiai situacijai, Paslaugų teikėjas nedelsiant susisieks su Klientu, paaiškins jam užsakymo nevykdymo (dalinio vykdymo) priežastis ir suderins Sutarties nutraukimo ar jos vykdymo pakeitimo sąlygas. Už dalinai atliktus darbus Klientas sumoka Paslaugų teikėjui.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Klientas, ne vėliau kaip 24 (dvidešimt keturias) valandas iki užsakymo numatytos vykdymo pradžios, gali atšaukti užsakymą, pranešus apie užsakymo atšaukimą. Užsakymas atšaukiamas, naudojantis informacija, kurią Klientas gavo patvirtinus užsakymą el. laišku ir / ar SMS žinute su užsakymo numeriu ir pagrindine užsakymo informacija. Tokiu atveju Klientui jo el. paštu ir / ar SMS žinute bus išsiunčiamas užsakymo atšaukimo pranešimas. Laiku neatšaukus užsakymo, Paslaugų teikėjas turi teisę Kliento prašyti apmokėti minimalų iškvietimo mokestį - už 1 (vieną) darbo valandą.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Klientas atsako už tinkamą užsakymo duomenų pateikimą<span className="Apple-converted-space">&nbsp;</span>bei turi nedelsdamas pranešti Paslaugų teikėjui, jei negalės sudaryti sąlygų Paslaugų teikėjui suteikti Paslaugas. Sudarydamas Sutartį Klientas patvirtina, kad jo pateikti duomenys yra teisingi. Esant situacijai, kai duomenys yra pateikiami netikslūs arba įsivelia klaida, Klientas nedelsiant informuoja Paslaugų teikėją ir Paslaugų teikėjas duomenis koreguoja.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Klientui pateikus neteisingus, neišsamius ar netikslius duomenis, Paslaugų teikėjas turi teisę nedelsiant ir nepranešus Klientui neregistruoti užsakymo, panaikinti užsakymą, apriboti ar sustabdyti Klientui Paslaugų teikimą.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Svetainėje taip pat yra pateikti avarinių tarnybų kontaktai. Šias paslaugas teikia ne Paslaugų teikėjas ir Taisyklės, įskaitant ir užsakymų teikimo bei apmokėjimo sąlygas avarinių tarnybų paslaugoms, nėra taikomos.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
									</ol>
								</li>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}><strong>PASLAUGŲ KAINOS IR APMOKĖJIMAS UŽ PASLAUGAS</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugų kainos yra nurodytos<span className="Apple-converted-space">&nbsp;</span><u><a href="http://www.ukvedziai.lt/" style={{ color: 'rgb(5, 99, 193)', textDecoration: 'underline' }}>www.ukvedziai.lt</a></u>. Paslaugų kainos nurodomos eurais su įskaičiuotu pridėtinės vertės mokesčiu.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugos Klientui teikiamos Paslaugų kainomis, galiojančiomis užsakymo pateikimo metu. Paslaugų kaina apskaičiuojama pagal valandinį įkainį t. y. galutinė Kliento mokėtina Paslaugų kaina apskaičiuojama pagal faktiškai sugaištą laiką, dauginant jį iš Paslaugų įkainio. Paslaugų teikėjas iki Paslaugų suteikimo Klientui gali nurodyti preliminarias Paslaugų kainas, tačiau tokių kainų nurodymas nebus laikomas galutine kaina. Paslaugų teikėjui pakeitus Paslaugų kainas, tokie pakeitimai bus taikomi tik užsakymams, pateiktiems po pakeitimų paskelbimo.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Į Paslaugų kainą neįskaičiuota medžiagų, kurias parūpina Paslaugų teikėjas, kaina. Už tokias medžiagas Klientas sumoka papildomai.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<AddedLIP
											text="Paslaugų teikėjo darbuotojas, atvykęs į Paslaugos teikimo vietą, Paslaugų teikėjo sistemoje fiksuoja Paslaugų teikimo pradžią. Paslaugų teikėjo darbuotojas išsiaiškina, ar bus reikalingos papildomos medžiagos. Jeigu medžiagų reikia, pagal žodinį susitarimą su Klientu, Paslaugų teikėjo darbuotojas vyksta į artimiausią prekybos vietą ir nuperka reikalingas medžiagas. Klientui pageidaujant, Klientas pats gali vykti į prekybos vietą įsigyti papildomų medžiagų. Paslaugų teikimo trukmės skaičiavimas nėra stabdomas iki Paslaugos atlikimo pabaigos (Paslaugos etapo arba visos Paslaugos).										"
										/>
										<AddedLIP
											text="Papildomų medžiagų, kuriomis pasirūpina Paslaugų teikėjas, kaina yra pateikiama PVM sąskaitoje faktūroje. Ji skaičiuojama prie prekybos vietoje sumokėtos kainos pridedant 10 (dešimt) procentų administracinio mokesčio."
										/>
										<AddedLIP
											text="Prieš atliekant Paslaugą, Klientui pageidaujant ir iš anksto raštu suderinus reikalingų medžiagų ir/ar įrangos tipą, kainą ir kitas detales, medžiagas ir/ar įrangą gali pirkti Paslaugų teikėjas. Tokiu atveju už medžiagas ir/ar įrangą Klientas Paslaugų teikėjui sumoka pagal iš anksto suderintą išankstinę sąskaitą, jeigu nesusitariama kitaip."
										/>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugoms gali būti taikoma minimali Paslaugų kaina (įkainis), tai yra minimali suma, kurią privaloma sumokėti už Paslaugas<strong>.<span className="Apple-converted-space">&nbsp;</span></strong>Taikomos minimalios Paslaugų kainos (įkainio) informacija nurodoma Svetainėje ir pateikiama Klientui iki užsakymo galutinio patvirtinimo.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Jei Klientas nesutinka su Paslaugų teikėjo kainomis ar įkainiais, jis turi neužsakyti Paslaugų, o jas užsakęs – atšaukti užsakymą iki Paslaugų teikimo pradžios, Taisyklių nustatyta tvarka.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugų teikėjo darbuotojui pradėjus teikti Paslaugas, Paslaugų teikėjo sistemoje fiksuojama Paslaugų teikimo pradžia. Atlikus su Paslaugomis susijusius darbus, Paslaugų teikėjo sistemoje fiksuojama darbų pabaiga ir atliktų darbų pagrindu automatiškai suformuojama PVM sąskaita faktūra, kurioje matoma darbų atlikimo trukmė, sunaudotų medžiagų sąrašas su kainomis ir kitomis papildomos paslaugos (pavyzdžiui, pristatymas). Klientas susipažįsta su PVM sąskaita faktūra ir ją pasirašo, taip sutikdamas su PVM sąskaitoje faktūroje nurodyta informacija. Suformuota PVM sąskaita faktūra persiunčiama Klientui el. paštu. Klientas, tokios sąskaitos pagrindu, apmoka<span className="Apple-converted-space">&nbsp;</span>Paslaugų kainą bei visus papildomus mokėjimus. Apmokėjimas vykdomas iš karto po Paslaugų suteikimo, mokėjimo kortele arba pavedimu į Paslaugų teikėjo sąskaitą - ne vėliau, kaip per<span className="Apple-converted-space">&nbsp;</span>3 (tris) darbo dienas nuo sąskaitos pateikimo.<span className="Apple-converted-space">&nbsp;</span>Atsiskaitymas grynaisiais pinigais negalimas.</p>
										</li>
										<AddedLIP
											text={'PVM sąskaita faktūra yra ir Atliktų darbų priėmimo-perdavimo aktas. Pasirašydamas šią PVM sąskaitą faktūrą Klientas patvirtina, kad darbai yra atlikti kokybiškai, pretenzijų dėl atliktų darbų ir sumontuotų medžiagų neturi.'}
										/>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Jei Klientas atsisako sumokėti už tinkamai suteiktas Paslaugas ar nesutinka su apmokėjimui nurodyta suma, Paslaugų teikėjo darbuotojas susisiekia su administracijos atstovu, kuris ginčą sprendžia, įvertindamas šiose Taisyklėse nurodytas sąlygas ir susidariusias aplinkybes. Nustačius, kad Paslaugos suteiktos tinkamai ir Paslaugų kaina Klientui nurodyta teisingai, Klientas privalo apmokėti už Paslaugas per 3 (tris) darbo dienas nuo PVM sąskaitos faktūros išsiuntimo jam dienos. Klientui vėluojant apmokėti PVM sąskaitą faktūrą, Paslaugų teikėjas turi teisę skaičiuoti 0,02 proc. dydžio delspinigius nuo neapmokėtos sumos už kiekvieną uždelstą dieną. Klientui neapmokėjus sąskaitos ilgiau nei 30 (trisdešimt) dienų nuo jos išsiuntimo dienos Paslaugų teikėjas, įspėjęs Klientą, ne vėliau kaip prieš 3 (tris) dienas, turi teisę kreiptis į skolas administruojančias įmones ir perduoti joms administruoti ar perleisti Kliento skolą.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Prieš pradedant darbus už reikalingas medžiagas Klientas Paslaugų teikėjui sumoka pagal iš anksto suderintą išankstinę sąskaitą.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugų teikėjas gali nepriimti Kliento užsakymų, jei Klientas turi įsiskolinimų už suteiktas Paslaugas, iki Klientas padengs tokius įsiskolinimus.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
									</ol>
								</li>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}><span lang="en-US"><strong>PASLAUG</strong></span><strong>Ų KOKYBĖ IR GARANTIJA</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugų teikėjas atliktiems su Paslaugomis susijusiems darbams suteikia 3 (trijų) mėnesių kokybės garantijos terminą. Paslaugų teikėjo teikiama kokybės garantija nevaržo Kliento – vartotojo teisių, kurias, įsigijus netinkamos kokybės Paslaugą, jam suteikia teisės aktai. Medžiagoms galioja gamintojo ar pardavėjo suteikta garantija.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Esant nekokybiškai suteiktoms Paslaugoms, Klientas turi teisę reikalauti pašalinti Paslaugų teikimo trūkumus, ar, jei Paslaugos trūkumas yra esminis, grąžinti už Paslaugas sumokėtą kainą, ar sumažinti Paslaugų kainą.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Pastebėjęs kokybės trūkumus, Klientas privalo apie tai nedelsiant pranešti Paslaugų teikėjui el. paštu<u><a>kokybe@ukvedziai.lt</a></u><span className="Apple-converted-space">&nbsp;</span>arba +37067000858 ir detaliai paaiškinti trūkumo esmę, o jei įmanoma, pateikti trūkumo iliustracijas (nuotraukas, filmuotą medžiagą ar pan.). Paslaugų teikėjas į Kliento užklausas dėl trūkumų reaguos nedelsiant, ne vėliau kaip per 3 (tris) darbo dienas nuo pranešimo gavimo ir suderins su Klientu trūkumo išsprendimo sąlygas (formą, būdą, laiką).<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Trūkumai, atsiradę dėl Paslaugų teikėjo kaltės, yra šalinami Paslaugų teikėjo sąskaita. Paaiškėjus, kad trūkumas atsirado dėl Kliento ar su juo susijusių asmenų kaltės, trūkumas yra šalinamas už jį Klientui apmokant pagal standartines Paslaugų teikimo kainas ar įkainius.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Klientui medžiagas įsigijus pačiam, Paslaugų teikėjas neprisiima atsakomybės už tokių medžiagų kokybę ar jų neigiamą įtaką Paslaugų kokybei.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
									</ol>
								</li>
								<li>
									<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>PASLAUGŲ TEIKIMO SĄLYGOS</strong></p>
									<ol>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Klientas įsipareigoja sudaryti tinkamas sąlygas Paslaugų suteikimui (pavyzdžiui, įleisti į patalpas, pateikti reikiamas medžiagas ir pan.), taip pat užtikrinti Paslaugų teikėjo darbuotojo saugumą (pavyzdžiui, uždaryti namuose esantį agresyviai besielgiantį naminį gyvūną į atskirą patalpą, atjungti nereikalingus ar nesaugius prietaisus ir pan.) bei pasirūpinti kitų asmenų (pavyzdžiui, patalpoje esančių vaikų) ir savo turto saugumu (pavyzdžiui, uždengti baldus, pašalinti nereikalingus kilimus ar pan.). Klientas turi reaguoti į Paslaugų teikėjo darbuotojo prašymus dėl jo, kitų asmenų ar turto saugumo užtikrinimo bei tinkamų sąlygų Paslaugoms teikti sudarymo.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Jei Paslaugų teikėjo darbuotojui atvykus į Paslaugų teikimo vietą paaiškėja, kad Kliento joje nėra (tai yra, Paslaugų teikėjo darbuotojas jo neranda sutartu Paslaugos teikimo laiku ir 15 (penkiolika) minučių po sutarto laiko), taip pat, jei Klientas neužtikrina pakankamo Paslaugų teikėjo darbuotojo ar kitų patalpoje esančių asmenų saugumo ar nepasirūpina priemonėmis, kurios yra būtinos Paslaugų teikimui (kai jomis turi pasirūpinti Klientas), ir dėl to Paslaugos negali būti suteiktos, laikoma, kad Paslauga nesuteikta dėl Kliento kaltės. Tokiu atveju, Paslaugų teikėjas gali Kliento pareikalauti<span className="Apple-converted-space">&nbsp;</span>apmokėti minimalų iškvietimo mokestį - už 1 (vieną) darbo valandą. Klientas, norėdamas gauti Paslaugą vėliau, turi užpildyti naują užsakymo formą.</p>
										</li>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugos, atsižvelgiant į reikiamų medžiagų kiekį, laiko sąnaudas ir kitas reikšmingas Paslaugai teikti aplinkybes, gali būti teikiamos etapais (dalimis). Tokiu atveju Paslaugų teikėjo darbuotojas su Klientu aptaria Paslaugų teikimo eigą. Klientas tokias Paslaugas priima ir už jas atsiskaito už kiekvieną etapą.</p>
										</li>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas įsipareigoja teikti kokybiškas Paslaugas pagal Kliento užsakymą. Paslaugoms teikti Paslaugų teikėjas įsipareigoja paskirti darbuotojus, kurie turi tinkamą kvalifikaciją, pasirūpinti visomis užsakymo įvykdymui reikalingomis medžiagomis, jeigu taip buvo sutarta iš anksto.</p>
										</li>
									</ol>
								</li>
								<li>
									<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>ŠALIŲ ATSAKOMYBĖ</strong></p>
									<ol>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Klientui atsisakius Paslaugų pagal užsakymą, Sutartis nutraukiama ir Klientas turi apmokėti už faktiškai suteiktas Paslaugas Taisyklėse nustatyta tvarka.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Jei dėl Paslaugų teikėjo neteisėtų veiksmų buvo padaryta žala Klientui, Paslaugų teikėjas įsipareigoja atlyginti tiesioginius Kliento nuostolius, išskyrus, kai teisės aktai įpareigoja Paslaugų teikėją atlyginti visus Kliento – vartotojo patirtus nuostolius.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p align="justify" style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas nelaikomas atsakingu už netinkamai suteiktas Paslaugas tais atvejais, kai Klientas nesudaro galimybės suteikti Paslaugų, pavyzdžiui, neįleidžia darbuotojo, nepasirūpina medžiagomis, pateikia netinkamas medžiagas, įskaitant netinkamos kokybės medžiagas, nesilaiko Paslaugų teikėjo instrukcijų ir pan.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
									</ol>
								</li>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}><strong>APSIKEITIMAS INFORMACIJA</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Paslaugų teikėjas pranešimus Klientui teikia<span className="Apple-converted-space">&nbsp;</span>Kliento užsakyme pateiktu el. paštu ar telefonu arba kitokiu būdu, kuriuo Klientas gali būti pasiekiamas.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Klientas visus pranešimus ir klausimus Paslaugų teikėjui &nbsp;siunčia el. paštu<span className="Apple-converted-space">&nbsp;</span><a><u>jusu</u><u>@ukvedziai.lt</u></a><span className="Apple-converted-space">&nbsp;</span>arba tel. +370 670 00858.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', backgroundColor: 'transparent', marginTop: '0.08in' }}>Visa Klientui siunčiama informacija laikoma gauta praėjus 3 (trims) valandoms nuo jos išsiuntimo momento. El. laiško ar kitokio pranešimo, skirto Klientui, kopijos buvimas Paslaugų teikėjo serveryje, duomenų bazėje ar kitokiame atitinkamą informaciją kaupiančiame įrenginyje yra tinkamas įrodymas apie bet kokios informacijos išsiuntimą.</p>
										</li>
									</ol>
								</li>
								<li>
									<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}><strong>BAIGIAMOSIOS NUOSTATOS</strong></p>
									<ol>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Visa informacija Klientui pateikiama Paslaugų teikėjo Svetainėje el. paštu, telefonu arba tiesiogiai, įskaitant, bet neapsiribojant, šias Taisykles, informacija apie Paslaugų teikėją, siūlomas paslaugas bei jų savybes, Paslaugų teikėjo teikiamas garantijas, laikoma pateikta Klientui raštu.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Taisyklėms ir Sutarčiai taikoma Lietuvos Respublikos teisė.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Paslaugų teikėjas gali bet kuriuo metu perleisti savo teises ir pareigas, kylančias iš šių Taisyklių, tretiesiems asmenims be Kliento sutikimo, tačiau užtikrindamas, kad, perleidus savo teises ir pareigas tretiesiems asmenims, Klientui teikiamų paslaugų kokybė nepablogės bei teisių ir pareigų apimtis nepakis.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Klientas - vartotojas turi galimybę spręsti su Paslaugų teikėju iškilusius ginčus ir elektroniniu būdu, nesikreipdamas į teismą. Pirmiausia, Klientas - vartotojas raštu el. paštu<span className="Apple-converted-space">&nbsp;</span><u>kokybe@ukvedziai.lt<span className="Apple-converted-space">&nbsp;</span></u>turi kreiptis į Paslaugų teikėją bei per 14 (keturiolika) dienų nuo pretenzijos gavimo, Paslaugų teikėjui neatsakius į vartotojo pretenziją, arba nepatenkinus vartotojo pretenzijos, Klientas - vartotojas prašymą ir (ar) skundą dėl iš Paslaugų teikėjo įsigytos Paslaugos gali pateikti Valstybinei vartotojų teisių apsaugos tarnybai (Vilniaus g. 25, 01402 Vilnius, telefonu 8 5 262 67 51, faks. 8 5 279 1466, elektroniniu paštu tarnyba@vvtat.lt, interneto svetainėje www.vvtat.lt, jos teritoriniams padaliniams apskrityse) ar užpildyti prašymo formą Elektroninėje vartotojų ginčų sprendimo platformoje, pasiekiamoje adresu http://ec.europa.eu/odr/. Ši sąlyga Klientams – verslininkams netaikoma.</p>
										</li>
										<li>
											<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', marginTop: '0.08in' }}>Visi tarp Paslaugų teikėjo ir Kliento kilę nesutarimai dėl Taisyklių yra sprendžiami derybų keliu. Šalims neišsprendus ginčo derybų būdu per 30 (trisdešimt) dienų, ginčai galutinai sprendžiami Lietuvos Respublikos įstatymų nustatyta tvarka. Jei imperatyvios įstatymų nuostatos (ginčų su vartotojais atveju ar pan.) nenumato kitaip, ginčai teismingi Vilniaus miesto teismams.<span className="Apple-converted-space">&nbsp;</span></p>
										</li>
									</ol>
								</li>
							</ol>
							<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}><br /><br /></p>
							<p style={{ lineHeight: '18.4px', textAlign: 'justify', orphans: 2, widows: 2, marginBottom: '0.08in', direction: 'ltr', caretColor: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', fontStyle: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', wordSpacing: '0px', WebkitTextSizeAdjust: 'auto', WebkitTextStrokeWidth: '0px', textDecoration: 'none', marginTop: '0.08in' }}>Šios taisyklės atnaujintos: 2023-01-27</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Politika