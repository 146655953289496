import { useTranslation } from "react-i18next";
import Vinis from '../assets/vinis.png';
import Garantija from '../assets/garantija.png';
import VinisMob from '../assets/vinisMob.png';



const GarantijaServ = () => {

    const { t, i18n } = useTranslation();


    return (
        <div className="neGrid">
            <div className="garantijaCont">
                <div className="garantija">
                   <div className="garantijaName"> <h1>{t('garantija')} </h1></div>
                    <a className="garantijaTxt"> {t('garantija_txt')} </a>
                    <div className="garantijaPoints">
                        <div className="punktasG"> <img className="vinis" src={Vinis} alt="Vinis" /> <img className="vinisMob" src={VinisMob} alt="Vinis" /> <a className="garantija_points">{t('garantija_points')[0]}</a></div>
                        <div className="punktasG"> <img className="vinis" src={Vinis} alt="Vinis" /> <img className="vinisMob" src={VinisMob} alt="Vinis" /> <a className="garantija_points">{t('garantija_points')[1]}</a></div>
                        <div className="punktasG"> <img className="vinis" src={Vinis} alt="Vinis" /> <img className="vinisMob" src={VinisMob} alt="Vinis" /> <p className="garantija_points">{t('garantija_points')[2]} <a href="https://energokomplektas.lt" target='_blank' className="juodasTxt">{t('garantija_points')[3]}</a></p></div>
                    </div>
                    <img className="garantijaImg" src={Garantija} alt="garantija" />
                </div>
            </div>
        </div>
    )
}

export default GarantijaServ