import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { TaskContext } from "../AppStates";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

const Archyvas = () => {
    const { id } = useParams()
    const [naujienos, setData] = useState([]);
    const [produktai, setProduktai] = useState([]);
    const { path } = useContext(TaskContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();


    const getNews = () => {
        var test = []
        axios
            .get(path + 'naujienos?populate=*&sort=date:desc&filters[show][$eq]=true', {
            })
            .then((response) => {
                test = response.data.data
                setData(test)
                console.log(test)

            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }
    useEffect(() => {
        getNews()
    }, [])

    const getProduktai = (ids) => {
        var test = []
        var query = ''

        ids.forEach((id, index) => {
            query += `&filters[id][$in][${index}]=${id}`;
        });

        axios
            .get(path + 'produktai?populate=*' + query, {
            })
            .then((response) => {
                test = response.data.data
                setProduktai(test)
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }

    useEffect(() => {
        if (naujienos && naujienos.length > 0)
            var ids = naujienos.map(e => {
                return e?.attributes?.produktai?.produktai.map(r => {
                    return r.id
                })
            })
        if (ids)
            getProduktai(ids[0])

    }, [naujienos])



    if (naujienos.length == 0) {
        return ('')
    }

    return (
        // Object.keys(naujienos).length > 0 ?
        <div>
            {naujienos.length == 0 && id ? '' :
                <ElementById
                    info={naujienos}
                    id={id}
                    produktai={produktai}
                />
            }
            <div className='lentele naujienos'>
                {naujienos.map((item, i) => (
                    item.id == id ?
                        '' :
                        <Multiple key={item.id}
                            item={item} />
                )
                )}
            </div>




        </div>
        // : <div className="newsContainer" id="News"></div>
    )
}

export default Archyvas

const ElementById = ({ info, id, produktai }) => {
    const { t } = useTranslation()
    const [item, setItem] = useState({})
    const navigate = useNavigate();
    const [number, setNumber] = useState(null);

    const i = id
    useEffect(() => {
        console.log(info)
        info.map((item, index) => {
            if (item.id == id) {
                setItem(item)
                setNumber(info.length - index)
            }
        })
    }, [info, id])
    if (!id || Object.keys(item).length == 0) {
        return ('')
    }
    return (
        <div className="atidaryta_naujiena">
            <div key={'naujiena-' + item.id} className="newsContainerV">
                <div className="archyvasImgV">
                    <div className="pipes1V" >
                        {item.attributes.showImg.data != undefined ?
                            <img key={'naujiena-img-' + i} src={item.attributes.showImg.data.attributes.url} />
                            : ''}
                    </div>
                </div>
                <div className="archyveRightV">
                    <div className="eight">
                        <div className="eighttxt2">
                            {number > 9 ? number : '0' + number}
                        </div>
                    </div>
                    <div className="archyveContainer">
                        <div className="newsTextContV">
                            <a className="newsName" key={'naujiena-title-' + i}>{item.attributes.title}</a>
                            <a className="naujienaData4" key={'naujiena-date-' + i}>{item.attributes.date.split('T')[0]}</a>
                            {/* <a className="naujienaPavadinimas" key={'naujiena-title-' + i}>{item.attributes.title}</a> */}
                            {/* <a className="naujienaTekstas" key={'naujiena-subtitle-' + i}>{item.attributes.subtitle}</a> */}
                            <div
                                dangerouslySetInnerHTML={{ __html: item.attributes.subtitle ? DOMPurify.sanitize(item.attributes.subtitle) : '' }}
                                className="naujienaTekstas4">
                            </div>
                            <div
                                dangerouslySetInnerHTML={{ __html: item.attributes.text ? DOMPurify.sanitize(item.attributes.text) : '' }}
                                className="naujienaTekstas24">
                            </div>
                            {item.attributes.buttonLink && item.attributes.buttonName ?

                                <button className="btnKviesti2 pointer" onClick={() => { navigate(item.attributes.buttonLink) }}>
                                    {item.attributes.buttonName}
                                </button>
                                : ''}


                        </div>

                    </div>
                </div>
            </div>
            <div className="produktas_container">
                {produktai.map(e => (

                    <div className="produkas"
                        key={'produktas-' + e.id}
                    >
                        <div className="product_photo">
                            <img key={'produktas-img-' + i} alt="prouct" src={e.attributes.showImg.data.attributes.url} />
                        </div>
                        <div className="newsName2" key={'produktas-title-' + i}>{e.attributes.title}</div>
                        <div className="naujienaTekstas_2" key={'produktas-subtitle-' + i} dangerouslySetInnerHTML={{ __html: e.attributes.subtitle ? DOMPurify.sanitize(e.attributes.subtitle) : '' }}></div>
                        <div className="naujienaTekstas22" key={'produktas-text-' + i} dangerouslySetInnerHTML={{ __html: e.attributes.text ? DOMPurify.sanitize(e.attributes.text) : '' }}></div>
                        <div className="prod_btn_container">
                            {e.attributes.buttonLink && e.attributes.buttonName ?
                                <button className="btnKviesti2 pointer" onClick={() => { navigate(e.attributes.buttonLink) }}>{e.attributes.buttonName}</button>
                                : ''}
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}
const Multiple = ({ item }) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    var i = item.id

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div key={'naujiena-' + item.id} className="newsContainer3">
            <div className="newsBlocks">
                <div className="archyvasImg3">
                    <div className="pipes4" >
                        {item.attributes.showImg.data != undefined ?
                            <img key={'naujiena-img-' + i} src={item.attributes.showImg.data.attributes.url} />
                            : ''}
                    </div>
                </div>
                <div className="archyveRight3">

                    <div className="archyveContainer">
                        <div className="newsTextCont2">
                            <a className="newsName3" key={'naujiena-title-' + i}>{item.attributes.title}</a>

                            <button className="btnKviesti3 pointer" onClick={() => { navigate("../archyvas/" + item.id) }}>{t('daugiau')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}