
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, useNavigate } from "react-router-dom";
import { TaskContext } from "./AppStates";
import Modal from 'react-modal';
import Fb from './assets/fb_dark.png';
import Ig from './assets/ig_dark.png';
import Menu from './assets/Menu.png';



import Logo from "./logo_ukv.svg";
const Navigation = () => {


    const navigate = useNavigate()
    const mobileNav = useRef(null)
    ////////////kalbu pakeitimo blokas////////
    const { t, i18n } = useTranslation();
    const [href, setHref] = useState('')
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const changeLanguage = (lng) => {
        try {
            if (lng != currentLng)
                i18n.changeLanguage(lng);
            window.location.reload()
        }
        catch (e) { console.log(e) }
    }
    const kalbos = t('languages')
    const [currentLng, setCurrentLng] = useState(i18n.language)
    let body = document.getElementsByTagName('body')
    document.body.classList.add(currentLng);
    ////////////////////////////////////////////////////////////// 

    const checkLink = (value) => {
        if (href == value) {
            return true
        }
        return false
    }

    const menuOff = () => {
        mobileNav.current.style.display = 'none';
        setTimeout(() => { forceUpdate() }, 100)
    }
    return (
        <div className={href ? `navBar ${href}` : "navBar"}>
            <div className="ham"
                onClick={() => {
                    mobileNav.current.style.display = 'block';
                }}
            ><img src={Menu} alt="Menu" /></div>

            <div className="navLogo" onClick={() => {
                // if(currentLng!='en'){
                //     window.location.replace("/")
                // }else{
                //     window.location.replace("/")
                // }

            }}>
                <a onClick={() => { setHref('') }} href="/#home"> <img src={Logo} alt="Logo" /></a>

            </div>
            <nav key='navigationConainer' className="navContainer" >
                <a key={t('iskviesti')}
                    className="navItem" onClick={() => {
                        navigate('/Sugedo', { scrollTo: "" })
                        setHref('Sugedo')
                    }} to="/Sugedo">{t('iskviesti')}</a>
                <a key='apie' onClick={() => {
                    navigate('/', { state: { scrollTo: "About" } })
                    setHref('')
                }} className="navItem"
                >{t('apie')}</a>
                <a key='paslaugos' onClick={() => {
                    navigate('/', { state: { scrollTo: "Services" } })
                    setHref('')
                }} className="navItem">{t('paslaugos')}</a>
                <a key='menesio_naujiena' onClick={() => {
                    navigate('/', { state: { scrollTo: "News" } })
                    setHref('')
                }} className="navItem">{t('menesio_naujiena')}</a>
                <a key='kontaktai' onClick={() => {
                    navigate('/', { state: { scrollTo: "Footer" } })
                    setHref('')
                }} className="navItem" >{t('kontaktai')}</a>
            </nav>

            <nav key='navigationConainerMob' className={href ? `mobileNav ${href}` : "mobileNav"} ref={mobileNav} >
                <div className="mobileTop">
                    <div className="mobileClose"
                        onClick={() => {
                            menuOff()
                        }}>✕</div>
                    <Link onClick={() => {
                        setHref('')
                        menuOff()
                    }} to="/" className="logoMenuMob"> <img src={Logo} alt="Logo" /></Link>
                </div>
                <div className="mobileNavigationList">
                    <div className="taskas">
                        <a key='iskviesti' className="navItemMob1 "
                            onClick={() => {
                                navigate('/Sugedo')
                                setHref('Sugedo')
                                menuOff()
                            }}>{t('iskviesti')}
                            {checkLink('Sugedo') ? <span className="dotNavBar" onClick={() => { menuOff() }}></span> : ''}
                        </a>
                    </div>
                    <div className="taskas">
                        <a key='paslaugos' className="navItemMob"
                            onClick={() => {
                                navigate('/', { state: { scrollTo: "Services" } })
                                setHref('Services')
                                menuOff()
                            }}
                        >{t('paslaugos')}
                            {checkLink('Services') ? <span className="dotNavBar"></span> : ''}
                        </a>
                    </div>
                    {console.log(href)}
                    <div className="taskas">
                        <a key='menesio_naujiena' className="navItemMob"
                            onClick={() => {
                                navigate('/', { state: { scrollTo: "News" } })
                                setHref('news')
                                menuOff()
                            }}
                        >{t('menesio_naujiena')}
                            {checkLink('news') ? <span className="dotNavBar"></span> : ''}

                        </a>

                    </div>
                    <div className="taskas">
                        <a key='apie' className="navItemMob" onClick={() => {
                            navigate('/', { state: { scrollTo: "About" } })
                            setHref('About')
                            menuOff()
                        }}>{t('apie')}
                            {checkLink('About') ? <span className="dotNavBar"></span> : ''}

                        </a>
                    </div>
                    <div className="footerTelMob">
                        <a href={'tel:' + t('irengimas_tel')}>{t("irengimas_tel")}</a>
                    </div>
                    <div className="navMobText">
                        <a className="juodasTxt" href="mailto: jusu@ukvedziai.lt">{t("info_mail")}</a>
                    </div>
                    {/* <div className="socIcon">
                        <img src={Fb} alt="Fb" />
                        <img src={Ig} alt="Ig" />
                    </div> */}

                    {/* <div>
                        <Link onClick={() => {
                            setShowDot(true)
                            mobileNav.current.style.display = 'none';
                            setHref('Sugedo')
                        }} 
                        key='iskviesti' className="navItemMob1 taskas " to="./Sugedo">{t('iskviesti')}
                            {showDot ? <Dot /> : null}
                        </Link>
                    </div>

                    <a onClick={() => {
                        mobileNav.current.style.display = 'none';
                    }} key='paslaugos' className="navItemMob" href="./#Services">{t('paslaugos')}  </a>
                    <Link onClick={() => {
                        mobileNav.current.style.display = 'none';
                    }} key='menesio_naujiena' className="navItemMob" to="./news">{t('menesio_naujiena')}</Link>
                    <a onClick={() => {
                        mobileNav.current.style.display = 'none';
                    }} key='apie' className="navItemMob" href="./#About">{t('apie')}</a>
                    <div className="footerTelMob">
                        <p>{t("irengimas_tel")}</p>
                    </div>
                    <div className="navMobText">
                        <p>{t("info_mail")}</p>
                    </div>
                    <div className="socIcon">
                        <img src={Fb} alt="Fb" />
                        <img src={Ig} alt="Ig" />
                    </div> */}







                </div>

            </nav>

            {/* /////////kalbu pakeitimo blokas//////////// */}
            {/* <div className="languageSelector">
                <div key="currentLng" className="currentLng">{currentLng}</div>
                <div key="changeLng" className="changeLng">
                    {kalbos.map((e,i) => (
                        <div>
                            {e != currentLng ? <div key={"lang-" + e} onClick={() => {
                                changeLanguage(e)
                            }} className="language" >{e}</div> : <div key={'epmty'}></div>}

                        </div>
                    ))}
                </div>
            </div> */}
            {/* ///////////////////////////////////////////////// */}
        </div>

    )
}

export default Navigation