import { useTranslation } from "react-i18next";
import Pipes from '../assets/vamzdis.png';

import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { TaskContext } from "../AppStates";
import { useNavigate } from 'react-router-dom';


const News = () => {

    const { t, i18n } = useTranslation();
    const [data, setData] = useState({});
    const { path } = useContext(TaskContext);
    const navigate = useNavigate();
    const [number, setNumber] = useState(null);

    const getNews = () => {
        var test = []
        axios
            .get(path + 'naujienos?populate=*&filters[show][$eq]=true&sort=date:desc', {
            })
            .then((response) => {
                test = response.data.data
                setData(test[0])
                console.log(test)
                setNumber(test.length)

            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }
    useEffect(() => {
        getNews()

    }, [])
    const [date, setDate] = useState(new Date().getMonth + 1);
    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setDate(new Date(data.attributes.createdAt.split('T')[0]).getMonth() + 1)
        }
    }, [data])

    

    return (


        Object.keys(data).length > 0 ?

            <div className="newsContainer" id="News">
                <div className="newsImg">
                    <div className="pipes3" >

                        <img src={data.attributes.showImg.data?.attributes.url} alt="Pipes" />
                    </div>
                </div>


                <div className="newsRight">

                    <div className="eight2">
                        <div className="eighttxt">
                            {number > 9 ? number : '0' + number}
                        </div>
                    </div>
                    <div className="contentContainer">
                        <div className="newsTextCont">
                            {console.log(data)}
                            <a className="newsName">{t('menesio_naujiena')}</a>
                            <a className="naujienaData">{data.attributes.date ? data.attributes.date.split('T')[0] : ''}</a>
                            <a className="naujienaPavadinimas">{data.attributes.title ? data.attributes.title : ''}</a>
                            {/* <a className="naujienaTekstas">{data.attributes.subtitle ? data.attributes.subtitle : ''}</a> */}
                            <div dangerouslySetInnerHTML={{ __html: data.attributes.subtitle ? data.attributes.subtitle : '' }}
                                className="naujienaTekstas2"></div>
                            <button onClick={() => {
                                navigate('/Archyvas/' + data.id)
                            }} className="btnKviesti pointer">{t("daugiau")}</button>
                        </div>
                    </div>
                </div>
            </div>
            : <div className="newsContainer" id="News"></div>

    )
}

export default News